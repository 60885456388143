import { Participant } from "interface/ParticipantInterface";
import classes from "./TotalScore.module.css";
import { FC } from "react";
import Organisation from "interface/OrganisationInterface";
import TotalOrgScoreRow from "./TotalOrgScoreRow";

interface Props {
  participants: Participant[];
  setIsModal: (b) => any;
  organisation?: Organisation;
}

const OrgTotalScore: FC<Props> = ({
  participants,
  setIsModal,
  organisation,
}) => {
  let redArr: JSX.Element[] = [];
  let notRedArr: JSX.Element[] = [];

  participants.forEach((p, index) => {
    let beforeTotal = 0;
    let afterTotal = 0;

    if (p.orgCheckIn) {
      for (const key in p.orgCheckIn) {
        if (Object.prototype.hasOwnProperty.call(p.orgCheckIn, key)) {
          const value = p.orgCheckIn[key];
          if (typeof value === "number") {
            beforeTotal += value;
          }
        }
      }
    }

    if (p.orgCheckOut) {
      for (const key in p.orgCheckOut) {
        if (Object.prototype.hasOwnProperty.call(p.orgCheckOut, key)) {
          const value = p.orgCheckOut[key];
          if (typeof value === "number") {
            afterTotal += value;
          }
        }
      }
    }

    const maxRating = organisation?.customMaxRating
      ? organisation.customMaxRating * 4
      : 40;
    const flagRatingPercentage = organisation?.flagRatingPercentage
      ? organisation.flagRatingPercentage
      : 60;
    const flagValue = (flagRatingPercentage / 100) * maxRating;

    if (
      beforeTotal < flagValue ||
      (afterTotal < flagValue && Math.sign(afterTotal) !== 0)
    ) {
      redArr.push(
        <TotalOrgScoreRow
          key={index}
          name={p.userName}
          beforeTotal={beforeTotal < 0 ? undefined : beforeTotal}
          afterTotal={afterTotal < 0 ? undefined : afterTotal}
          participant={p}
          setIsModal={setIsModal}
          organisation={organisation}
        />
      );
    } else {
      notRedArr.push(
        <TotalOrgScoreRow
          key={index}
          name={p.userName}
          beforeTotal={beforeTotal !== Infinity ? beforeTotal : undefined}
          afterTotal={afterTotal !== Infinity ? afterTotal : undefined}
          participant={p}
          setIsModal={setIsModal}
          organisation={organisation}
        />
      );
    }
  });

  return (
    <div className={`${classes["container"]} shadow-md`}>
      <h1
        className={`sticky font-bold text-lg justify-self-center text-default mb-4`}
      >
        For facilitator info only
      </h1>
      {organisation?.enableFlagRating &&
        organisation.enableFlagRating === true &&
        redArr.length > 0 && (
          <h3 className={classes["red-note"]}>
            Take special note of those
            <strong className="whitespace-pre"> above </strong>
            the dotted line
          </h3>
        )}

      <div className={`${classes["header"]} text-default`}>
        <div></div>
        <h1>Name</h1>
        <h1 className="justify-self-center">Before</h1>
        <h1 className="justify-self-center">After</h1>
      </div>
      <div className={classes["row-container"]}>
        {redArr.map((p) => p)}
        {organisation?.enableFlagRating &&
          organisation.enableFlagRating === true &&
          redArr.length > 0 && (
            <div className="border-dashed border-yellow-900 border"></div>
          )}
        {notRedArr.map((p) => p)}
      </div>
    </div>
  );
};

export default OrgTotalScore;
