import { PinGenerator } from "components/utility/UtilityFunctions";
import { Timestamp } from "firebase/firestore";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import Organisation, {
  defaultDataConsentContent,
} from "interface/OrganisationInterface";
import { Profile } from "interface/ProfileInterface";
import { defaultGCSessionModel } from "models/growthCircleSession";
import { defaultComponents } from "pages/AllCircles/GenericSessionPage/ComponentMapping";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { projectFirestore, timestamp } from "../firebase/config";

interface GrowthCircleTypeInfo {
  title: string;
  remarks: string;
  shortDescription: string;
  objectives: string[];
  whoForThis: string[];
  tags: string | null;
  subTags: string[];
  targetAudience: undefined;
  addedBy: string | undefined;
}

export const useGrowthCirclesType = () => {
  const [growthcircletypes, setGrowthCircleTypes] = useState<Organisation[]>(
    []
  );
  const navigate = useNavigate();

  const copyDocumentWithNestedCollections = useCallback(
    async (
      toDocumentID: string,
      sourceDocumentId: string,
      targetCollection: string
    ) => {
      try {
        // Fetch data from the source document
        const sourceDocumentRef = projectFirestore
          .collection("organisations")
          .doc(sourceDocumentId)
          .collection(targetCollection);

        const sourceDocumentSnapshot = await sourceDocumentRef.get();

        // Check if the source document is empty and log a message
        if (sourceDocumentSnapshot.empty) {
          console.warn(
            "Source document is empty for collection:",
            targetCollection
          );
          return; // Proceed as normal
        }

        // Iterate through each document in the source collection
        sourceDocumentSnapshot.forEach(async (docSnapshot) => {
          // Get the data from the document
          const documentData = docSnapshot.data();

          // Create a new document in the target collection
          await projectFirestore
            .collection("organisations")
            .doc(toDocumentID)
            .collection(targetCollection)
            .add(documentData);
        });

        // You may want to return something here if needed
      } catch (error) {
        console.error("Error copying document with nested collections:", error);
        throw new Error("Error copying document with nested collections");
      }
    },
    []
  );

  const getAllGrowthCircleType = useCallback(() => {
    const updatedGrowthCircleType: Organisation[] = [];
    let GrowthCircleTypeUnsubscribe: (() => void) | undefined;

    try {
      const growthCircleTypeQuery = projectFirestore
        .collection("organisations")
        .where("isAType", "==", true);

      GrowthCircleTypeUnsubscribe = growthCircleTypeQuery.onSnapshot(
        (growthCirclesTypesSnapshot) => {
          updatedGrowthCircleType.length = 0; // Clear the array
          growthCirclesTypesSnapshot.forEach((growthCircleTypeSnapshot) => {
            updatedGrowthCircleType.push(
              growthCircleTypeSnapshot.data() as Organisation
            );
          });

          setGrowthCircleTypes(updatedGrowthCircleType);

          // Set the state or do whatever you need with the topics
          // setTopicData(updatedTopics);
        }
      );
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (GrowthCircleTypeUnsubscribe) {
        GrowthCircleTypeUnsubscribe();
      }
    };
  }, []);

  const getGrowthCircleTypeById = useCallback(async (id: string) => {
    try {
      const growthCircleTypeQuery = await projectFirestore
        .collection("organisations")
        .doc(id)
        .get();
      const growthCircleTypeData = growthCircleTypeQuery.data();

      return growthCircleTypeData;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getGrowthCircleTypeByName = useCallback(async (name: string) => {
    try {
      const growthCircleTypeQuery = await projectFirestore
        .collection("organisations")
        .where("name", "==", name)
        .get();

      // Check if there are any documents matching the query
      if (!growthCircleTypeQuery.empty) {
        // Access the first document in the query results
        const growthCircleTypeDoc = growthCircleTypeQuery.docs[0];
        const growthCircleTypeData = {
          id: growthCircleTypeDoc.id,
          data: growthCircleTypeDoc.data(),
        };

        return growthCircleTypeData;
      } else {
        // Handle the case where no document matches the query

        return null;
      }
    } catch (error) {
      // Throw the error so it can be handled by the caller
      throw new Error("Error fetching growth circle type");
    }
  }, []);

  const AddNewGrowthCircleType = useCallback(
    async (_data: GrowthCircleTypeInfo) => {
      try {
        const data: Organisation = {
          id: "",
          name: _data.title,
          users: {},
          invites: {},
          components: defaultComponents,
          inheritGeneralTopics: true,
          isAType: true,
          createdAt: timestamp.fromDate(new Date()),
          remarks: _data.remarks,
          shortDescription: _data.shortDescription,
          objectives: _data.objectives,
          whoForThis: _data.whoForThis,
          tags: _data.tags,
          subTags: _data.subTags,
          targetAudience: _data.targetAudience,
          addedBy: _data.addedBy,
          enableFlagRating: true,
          flagRatingPercentage: 60,
          setMaxRating: false,
          maxRating: 10,
          customMaxRating: 10,
          enableCustomEndGrowthCircles: false,
          enableCustomDataConsent: false,
          customDataConsent: defaultDataConsentContent,
          dataConsentValidityPeriod: 1,
          dataConsentValidityUnit: "hour(s)",
        };
        const ref = await projectFirestore
          .collection("organisations")
          .add(data);
        await ref.update({ id: ref.id });
        const gb = await getGrowthCircleTypeByName("Growthbeans");
        if (ref.id && gb) {
          await copyDocumentWithNestedCollections(ref.id, gb?.id, "roles");
        }
        navigate(`/all-circles/admin/growthcircles-type/${ref.id}`);
      } catch (error) {
        console.error(error);
      }
    },
    [copyDocumentWithNestedCollections, getGrowthCircleTypeByName, navigate]
  );

  const AddNewGrowthCircleTypeByTemplate = useCallback(
    async (
      _data: GrowthCircleTypeInfo,
      template: string | null,
      profile: Profile
    ) => {
      if (template) {
        const org = await getGrowthCircleTypeByName(template);

        try {
          const data = {
            ...org?.data,
            name: _data.title,
            users: {
              [profile.uid]: "facilitator",
            },
            isAType: true,
            createdAt: timestamp.fromDate(new Date()),
            remarks: _data.remarks,
            shortDescription: _data.shortDescription,
            objectives: _data.objectives,
            whoForThis: _data.whoForThis,
            tags: _data.tags,
            subTags: _data.subTags,
            targetAudience: _data.targetAudience,
            addedBy: _data.addedBy,
            copiedOrgId: org?.id,
          };

          const ref = await projectFirestore
            .collection("organisations")
            .add(data);
          await ref.update({ id: ref.id });
          if (org?.id) {
            await copyDocumentWithNestedCollections(
              ref.id,
              org?.id,
              "activities"
            );
            await copyDocumentWithNestedCollections(
              ref.id,
              org?.id,
              "questions"
            );
            await copyDocumentWithNestedCollections(ref.id, org?.id, "topics");
            await copyDocumentWithNestedCollections(ref.id, org?.id, "roles");
            await copyDocumentWithNestedCollections(
              ref.id,
              org?.id,
              "feedbackQuestions"
            );
          }

          navigate(`/all-circles/admin/growthcircles-type/${ref.id}`);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [copyDocumentWithNestedCollections, getGrowthCircleTypeByName, navigate]
  );

  const getTemplateComponents = useCallback(
    async (template: string): Promise<string[]> => {
      try {
        const org = await getGrowthCircleTypeByName(template);
        const { components } = org?.data as Organisation;
        if (org) {
          return components || [];
        } else {
          return defaultComponents;
        }
      } catch (error) {
        console.error(error);
        return defaultComponents;
      }
    },
    [getGrowthCircleTypeByName]
  );

  // utilities
  const getDataByLabelObject = useCallback((labelObjectToFind, Data) => {
    const foundData = Data.find(
      (item) =>
        item.value === labelObjectToFind.value &&
        item.label === labelObjectToFind.label
    );
    return foundData ? foundData.value : null;
  }, []);

  const getDataByName = useCallback((Data) => {
    if (Array.isArray(Data)) {
      const foundData = Data.map((item) => {
        if (item.value) {
          return item.value;
        } else {
          return item;
        }
      });

      return foundData ? foundData : Data;
    } else {
      return Data["value"] ? Data["value"] : Data;
    }
  }, []);

  const getValuesByLabelObjectsArray = useCallback(
    (labelObjectsArray, DataToSearch) => {
      return labelObjectsArray.map((labelObject) => {
        const foundData = DataToSearch.find(
          (item) =>
            item.value.trim().toLowerCase() ===
              labelObject.value.trim().toLowerCase() &&
            item.label.trim().toLowerCase() ===
              labelObject.label.trim().toLowerCase()
        );
        if (!foundData) {
          console.warn("No match found for:", labelObject);
        }
        return foundData ? foundData.value : null;
      });
    },
    []
  );

  const getValuesByLabelObjectsArrayOnArraySet = useCallback(
    (labelObjectsArray, Data) => {
      return labelObjectsArray.map((labelObject) => {
        const foundData = Data.find(
          (item) =>
            item.name.trim().toLowerCase() ===
            labelObject.label.trim().toLowerCase()
        );
        if (!foundData) {
          console.warn("No match found for:", labelObject);
        }
        return foundData ? foundData.name : null;
      });
    },
    []
  );

  const checkForExistingGC = useCallback(async (id: string) => {
    try {
      const growthCircleTypeQuery = await projectFirestore
        .collection("GrowthCircles")
        .where("typeID", "==", id)
        .get();

      if (!growthCircleTypeQuery.empty) {
        // Access the first document in the query results
        const growthCircleTypeDoc = growthCircleTypeQuery.docs[0];
        const growthCircleTypeData = {
          id: growthCircleTypeDoc.id,
          data: growthCircleTypeDoc.data(),
        };

        return growthCircleTypeData;
      } else {
        // Handle the case where no document matches the query

        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const deleteGCType = useCallback(async (id: string) => {
    try {
      await projectFirestore.collection("organisations").doc(id).delete();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const addNewGCSessionAsType = useCallback(
    async (profile: Profile, organisation: Organisation, parentID: string) => {
      try {
        const newGCSession: GrowthCircleSession = {
          ...defaultGCSessionModel,
          sessionId: `${organisation.name}-GrowthCircle-${profile?.displayName}`,
          facilOwner: `${profile?.id}`,
          name: organisation.name,
          date: Timestamp.fromDate(new Date()),
          mentor: `${profile?.displayName}`,
          venue: [
            {
              id: "text",
              text: "Event",
              value: "Event",
              year: new Date().getFullYear(),
            },
          ],
          status: "Available",
          invite_code: PinGenerator(4),
          sessionExpire: 2,
          organisation: organisation.name,
          type: organisation.name,
          createdAt: Timestamp.fromDate(new Date()),
          sessionInstance: uuid(),
          typeID: parentID,
          isAType: true,
        };

        const docRef = await projectFirestore
          .collection("GrowthCircles")
          .add(newGCSession);

        await docRef.update({ uid: docRef.id });

        if (docRef) {
          return docRef.id;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    getAllGrowthCircleType();
  }, [getAllGrowthCircleType]);

  return {
    AddNewGrowthCircleType,
    growthcircletypes,
    getGrowthCircleTypeById,
    getGrowthCircleTypeByName,
    AddNewGrowthCircleTypeByTemplate,
    getTemplateComponents,
    copyDocumentWithNestedCollections,
    getDataByLabelObject,
    getValuesByLabelObjectsArray,
    getValuesByLabelObjectsArrayOnArraySet,
    getDataByName,
    checkForExistingGC,
    addNewGCSessionAsType,
    deleteGCType,
  };
};
