import SliderCustom from "components/SliderCustom/SliderCustom";
import BackAndNext from "components/utility/BackAndNext";
import EmptySpace from "components/utility/EmptySpace";
import useParticipants from "hooks/useParticipants";
import { useEffect, useState } from "react";
import classes from "./CheckOutAfter.module.css";
import React from "react";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import toast from "react-hot-toast";

import { useOrgORS } from "hooks/organisation/useOrgORS";
import usePromise from "hooks/utility/usePromise";
import SimpleLoader from "components/Loaders/SimpleLoader";

const CheckOrgOutAfterComponent = ({ backAndNextHandler }: ComponentProps) => {
  // const userStorageKey = profile!.id + "_";
  const { updateSessionORScheckOutValues } = useParticipants();

  const { selectedOrganisation } = useOrganisationContext();
  const [isAnyZero, setIsAnyZero] = useState(false); // Initialize as false
  const { ors } = useOrgORS();
  const initialQuestionValues = {};
  const { isLoading, resolve } = usePromise();

  // Function to initialize questionValues
  const initializeQuestionValues = () => {
    ors.forEach((question) => {
      initialQuestionValues[question.title.replace(/ /g, "_")] = 1;
    });

    // Set questionValues to the initialized values
    setQuestionValues(initialQuestionValues);
  };

  useEffect(() => {
    if (!ors) return;
    initializeQuestionValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ors]);

  const [questionValues, setQuestionValues] = useState(initialQuestionValues);

  const isAnyValueZero = () => {
    // Check for zero values in questionValues
    for (const questionId in questionValues) {
      if (questionValues[questionId] === 0) {
        return true; // Found a value equal to 0
      }
    }
    return false; // No value is equal to 0
  };

  function isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    // Function to check if any value is equal to 0
    if (!questionValues) return;

    // Update local storage key "ORSquestionValues" with the new questionValues

    localStorage.setItem("ORSquestionValues", JSON.stringify(questionValues));

    const hasZeroValue = isAnyValueZero();
    setIsAnyZero(hasZeroValue);

    // eslint-disable-next-line
  }, [questionValues]);

  const removeDragRatingsFromStorage = () => {
    localStorage.removeItem("ORSquestionValues");
  };

  const submitHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isAnyZero) {
      toast.error("Please complete the ORS before proceeding");
    } else {
      resolve(() => updateSessionORScheckOutValues(questionValues)).then(() => {
        removeDragRatingsFromStorage();
        backAndNextHandler.nextCallback(e);
      });
    }
  };

  return (
    <React.Fragment>
      <EmptySpace height={"10px"} />
      <p className="text-center font-semibold mt-3 text-sm w-3/4">
        {selectedOrganisation?.name === "NTU Career & Attachment Office"
          ? "Answer these questions again. On a scale of 1-10, how much clarity do you have in the following areas."
          : "Answer these questions again. How are you feeling now in the following areas?"}
      </p>
      {ors &&
      !isEmpty(localStorage.getItem("ORSquestionValues")) &&
      initialQuestionValues ? (
        ors.map((question) => (
          <div
            key={question.id}
            className="w-[320px] md:w-[400px] mx-auto mt-5"
          >
            <div className="w-full mx-auto">
              <div className={`${classes.box} shadow-md`}>
                <div className={`${classes.header}`}>{question.title}</div>
                <div className={classes["header-description"]}>
                  {question.sub_title}
                </div>
                <div className="flex justify-center px-2">
                  {/* Step 2: Create state variables for each question */}
                  <SliderCustom
                    withBackground={true}
                    maxValue={selectedOrganisation?.customMaxRating ?? 10}
                    value={
                      questionValues[question.title.replace(/ /g, "_")] ||
                      question.min
                    }
                    setValue={(value) => {
                      // Step 3: Update the state variable when the slider changes
                      setQuestionValues((prevValues) => ({
                        ...prevValues,
                        [question.title.replace(/ /g, "_")]: value,
                      }));

                      // Check for zero values when a slider value changes
                      const hasZeroValue = isAnyValueZero();
                      setIsAnyZero(hasZeroValue);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <SimpleLoader />
      )}

      <EmptySpace height={"40px"} />

      <BackAndNext
        {...backAndNextHandler}
        customNextText={isLoading ? "Saving..." : "Next"}
        nextCallback={submitHandler}
        nextAllowed={isAnyZero === false && backAndNextHandler.nextAllowed}
      />

      <EmptySpace height={"60px"} />
    </React.Fragment>
  );
};

export default CheckOrgOutAfterComponent;
