import { useAuthContext } from "hooks/useAuthContext";

import useGetParticipants from "hooks/useGetParticipants";
import { useEffect, useState } from "react";
import classes from "./RatingAverage.module.css";

import { useFirestore } from "hooks/useFirestore";
import EmptySpace from "components/utility/EmptySpace";

import { checkHasPermission, Role } from "models/organisationRole";
import Instruction from "components/utility/Instruction";
import { P_SEE_AVERAGE_RATING } from "models/permission";
import BackAndNext from "components/utility/BackAndNext";
import { ComponentProps } from "../GenericSessionPage/ComponentMapping";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";
import OrgTotalScore from "./OrgTotalScore";

import UserBarHandle from "components/RatingAverage/UserBarHandle";
import { Participant } from "interface/ParticipantInterface";
import { useOrgORS } from "hooks/organisation/useOrgORS";
import Slider from "components/Slider/Slider";
import Organisation from "interface/OrganisationInterface";
import useOrganisationRoleContext from "hooks/organisation/useOrganisationRoleContext";

/**
 * Page that shows the average of all participants after user has rated their initial checkIns
 */
const RatingOrgAverageComponent = ({
  setHasModal,
  backAndNextHandler,
}: ComponentProps) => {
  const { selectedOrganisation } = useOrganisationContext();
  const { roles } = useOrganisationRoleContext();
  const { ors } = useOrgORS();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    if (!selectedOrganisation) return;
  }, [selectedOrganisation]);

  const PAGE_CONSTANTS = {
    PAGE_TITLE:
      selectedOrganisation?.name === "NTU Career & Attachment Office"
        ? ""
        : "Check out how everyone's feeling!",
  };

  const INSTRUCTIONS_RATING_AVERAGE =
    selectedOrganisation?.name === "NTU Career & Attachment Office"
      ? "Check out average of everyone's clarity about their journey"
      : "Embrace the different emotional states and wellbeing of everyone";

  const { updateDocument } = useFirestore("users");

  const { profile } = useAuthContext();
  const { participants } = useGetParticipants();

  const [showContent] = useState(true);

  const [currentCheckInType, setCurrentCheckInType] = useState<string | null>(
    ""
  );

  const countAllSubmitted = () => {
    let count = 0;

    participants.forEach((p) => {
      if (p.checkIn.individually >= 0) {
        count++;
      }

      if (p.orgCheckIn) {
        const orgCheckInValues = Object.values(p.orgCheckIn);
        orgCheckInValues.forEach((value) => {
          if (typeof value === "number" && value >= 0) {
            count++;
          }
        });
      }
    });

    return count;
  };

  const getGroupedParticipantsByCheckInKey = (
    key: string,
    participants: Participant[]
  ) => {
    let result: Participant[][] = [];
    let groupArray: Participant[] = [];

    const participantsClone: Participant[] = [...participants]
      .filter((participant) => participant.orgCheckIn)
      .sort(
        (participant1, participant2) =>
          (participant1.orgCheckIn &&
            participant1.orgCheckIn[key.replace(/ /g, "_")]) -
          (participant2.orgCheckIn &&
            participant2.orgCheckIn[key.replace(/ /g, "_")])
      );

    for (let i = 0; i < participantsClone.length; i++) {
      const currCheckIn =
        participantsClone[i].orgCheckIn &&
        participantsClone[i].orgCheckIn![key.replace(/ /g, "_")];
      const prevCheckIn =
        participantsClone[i - 1]?.orgCheckIn &&
        participantsClone[i - 1]?.orgCheckIn![key.replace(/ /g, "_")];

      if (currCheckIn !== prevCheckIn) {
        groupArray = [];
        result.push(groupArray);
      }

      groupArray.push(participantsClone[i]);
    }

    return result;
  };

  /**
   * Calculates the average of an array of numbers.
   * @param numbers - Array of numbers
   * @returns The average of the numbers.
   */
  const calculateAverage = (numbers) => {
    if (numbers.length === 0) {
      return 0;
    }

    const sum = numbers.reduce((acc, current) => acc + current, 0);
    return sum / numbers.length;
  };

  useEffect(() => {
    if (!profile) return;

    const data = { pageStep: window.location.pathname };
    updateDocument(profile.uid, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, profile]);

  useEffect(() => {
    if (!ors) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ors]);

  const getOrgCheckData = (key: string, participants: Participant[]) => {
    if (selectedOrganisation) {
      const _filteredRoleParticipants = filterParticipantsByRole(
        participants,
        selectedOrganisation,
        roles
      );

      const groupedParticipants = getGroupedParticipantsByCheckInKey(
        key,
        _filteredRoleParticipants
      );

      const average = calculateAverage(
        groupedParticipants.map((participants) =>
          participants[0].orgCheckIn
            ? participants[0].orgCheckIn[key.replace(/ /g, "_")]
            : 1
        )
      );

      const otherValues = groupedParticipants.map((participants, index) => (
        <UserBarHandle
          max={selectedOrganisation?.customMaxRating ?? 10}
          key={index}
          checkInType={key + index}
          currentCheckInType={currentCheckInType}
          participants={participants}
          values={
            participants[0].orgCheckIn &&
            typeof participants[0].orgCheckIn[key.replace(/ /g, "_")] ===
              "number" &&
            Number(participants[0].orgCheckIn[key.replace(/ /g, "_")]) >= 0
              ? [participants[0].orgCheckIn[key.replace(/ /g, "_")]]
              : [0]
          }
          setCurrentCheckInType={setCurrentCheckInType}
        />
      ));

      return { average, otherValues };
    }
  };

  // Create a Set to store unique keys
  const uniqueKeysSet = new Set<string>();

  // Iterate through participants and add unique orgCheckIn keys to the Set
  participants.forEach((participant) => {
    if (participant.orgCheckIn) {
      Object.keys(participant.orgCheckIn).forEach((key) => {
        const lowerCaseKey = key.toLowerCase().replace(/ /g, "_");
        if (
          ors.some(
            (orsObj) =>
              orsObj.title.replace(/ /g, "_").toLowerCase() === lowerCaseKey
          )
        ) {
          uniqueKeysSet.add(lowerCaseKey);
        }
      });
    }
  });

  const filterParticipantsByRole = (
    participants: Participant[],
    selectedOrganisation: Organisation,
    roles: Role[]
  ) => {
    // Map over the participants array
    return participants.filter((participant) => {
      // Get the participant's role from the selected organisation
      const participantRole = selectedOrganisation?.users[participant.userId];

      // Find the corresponding role in the roles array
      const role = roles
        .filter((p) => p.isEnable !== false)
        .find((r) => r.name === participantRole);

      // Check if the role is found and if exCludedToCalc is not true
      return role && !role.exCludedToCalc;
    });
  };

  useEffect(() => {
    if (!profile) return;

    const _role = roles.filter(
      (role) => role.name === selectedOrganisation?.users[profile.uid]
    );

    setHasPermission(checkHasPermission(_role[0], P_SEE_AVERAGE_RATING));
    // eslint-disable-next-line
  }, [profile, roles]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [hasPermission]);

  // Convert the Set back to an array

  const allOrgCheckInKeys = ors.map((orsItem) => orsItem.title);
  return (
    <div className={classes["rating-average-container"]}>
      <div className={classes["title"]}>
        <h1 className="text-sm font-semibold text-center">
          {PAGE_CONSTANTS.PAGE_TITLE}
        </h1>
        <h2 className="text-center py-2 font-semibold text-slate-600">
          {countAllSubmitted()} out of {participants.length} submitted
        </h2>
        <hr />
      </div>
      <Instruction text={INSTRUCTIONS_RATING_AVERAGE} />
      {/* Content */}
      {allOrgCheckInKeys.map((key, index) => {
        const orgCheckData = showContent
          ? getOrgCheckData(key, participants)
          : null;

        return (
          showContent && (
            <div key={key} className={`${classes["rating-row"]} py-4`}>
              {/* textual content */}
              <div className={classes["textual-content"]}>
                <h2>{allOrgCheckInKeys[index]}</h2>
              </div>
              {/* slider */}

              {orgCheckData && orgCheckData.average > 0 ? (
                <>
                  {selectedOrganisation?.setMaxRating ? (
                    <Slider
                      max={selectedOrganisation?.customMaxRating ?? 10}
                      values={[
                        orgCheckData ? orgCheckData.average.toFixed(1) : 1,
                      ]}
                      setValues={[0]}
                      icon={false}
                      isDisabled={true}
                      // eslint-disable-next-line react/style-prop-object
                      style={"average"}
                      otherValues={orgCheckData ? orgCheckData.otherValues : []}
                    />
                  ) : (
                    <Slider
                      max={10}
                      values={[
                        orgCheckData ? orgCheckData.average.toFixed(1) : 1,
                      ]}
                      setValues={[0]}
                      icon={false}
                      isDisabled={true}
                      // eslint-disable-next-line react/style-prop-object
                      style={"average"}
                      otherValues={orgCheckData ? orgCheckData.otherValues : []}
                    />
                  )}
                </>
              ) : (
                <div>
                  <p>Participant role is excluded from average calculation</p>
                </div>
              )}
            </div>
          )
        );
      })}
      <EmptySpace height="2rem" />
      {hasPermission ? (
        <OrgTotalScore
          organisation={selectedOrganisation || undefined}
          participants={participants}
          setIsModal={setHasModal}
        />
      ) : (
        ""
      )}
      <BackAndNext {...backAndNextHandler} />
    </div>
  );
};

export default RatingOrgAverageComponent;
