import { Link } from "react-router-dom";
import { ChangeEvent, useRef, useState } from "react";

import PageWrapper from "components/utility/PageWrapper";

import Checkbox from "assets/Checkbox";
import TickedCheckbox from "assets/TickedCheckbox";
import BackButton from "assets/BackButton";
import WelcomeMessage from "components/Login/WelcomeMessage";
import InputField from "components/utility/Forms/InputField";
import Button from "components/utility/Button";
import TermsModal from "components/Login/TermsModal";
import classes from "./Signup.module.css";
import ReCAPTCHA from "react-google-recaptcha";

//import hook and useState
import { useSignup } from "../../hooks/useSignup";
import toast, { Toaster } from "react-hot-toast";
import PasswordChecker from "./PasswordChecker";
import { checkPasswordError } from "../../utility/inputValidation";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const { usernameError, emailError, passwordError, signup, isPending, error } =
    useSignup();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const recaptchaRef = useRef();
  const [verified, setVerified] = useState(false);
  const [isStrong, setIsStrong] = useState(false);

  const checkboxHandler = () => {
    if (termsAccepted === true) {
      setTermsAccepted(false);
    } else {
      setTermsAccepted(true);
    }
  };

  const handleChange = (e: string) => {
    const newPassword: string = e;
    setPassword(newPassword);

    // Set the state based on the criteria
    setIsStrong(checkPasswordError(newPassword));

    if (!checkPasswordError(newPassword)) {
      setShowTerms(false);
      setTermsAccepted(false);
    }
  };

  const setConfirmPasswordHandler = (e: string) => {
    setConfirmPassword(e);
    handleChange(password);
    // Check if the password matches the confirmation
    const matches = password === e;
    setIsStrong(matches); // Update isStrong based on matches
    if (!matches) {
      setShowTerms(false);
      setTermsAccepted(false);
    }
  };

  const buttonStyle = termsAccepted
    ? {
        fontSize: "1em",
        fontWeight: "600",
      }
    : {
        fontSize: "1em",
        fontWeight: "600",
        borderColor: "#C4C4C4",
        color: "#C4C4C4",
      };

  const showTermsHandler = () => {
    setShowTerms(true);
  };

  const hideTermsHandler = () => {
    setShowTerms(false);
  };

  //registerUser
  const submitHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (password === confirm_password) {
      signup(email, password, displayName);
    } else {
      toast.error("Passwords do not match");
    }
  };

  const handleRecaptcha = (response: string) => {
    verifyRecaptcha(response);

    // send the response token to your server for verification
  };
  const verifyRecaptcha = (response: string) => {
    const url =
      "https://us-central1-nobeans-110a4.cloudfunctions.net/verifyRecaptcha";
    const params = new URLSearchParams();

    params.append("response", response);

    return fetch(url, {
      method: "POST",
      body: params,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.success) {
          handleChange(confirm_password);
          setVerified(true);
        } else {
          setVerified(false);
          toast.error("failed captcha please try again");
        }
        // handle the response data from the reCAPTCHA verification
      })
      .catch((error) => {
        console.error(error);
        // handle any errors that occurred during the request
      });
  };

  return (
    <PageWrapper>
      <div className="min-h-[80vh] my-8 flex justify-center items-center">
        <Toaster />
        <TermsModal
          show={showTerms}
          onClick={hideTermsHandler}
          onAccept={checkboxHandler}
        />
        <div className="flex flex-col items-center">
          <div id="terms-modal" />
          <div className={classes.header}>
            <BackButton />
            <WelcomeMessage />
          </div>
          <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.inputs}>
              <InputField
                text="Username"
                isRequired={true}
                type={"text"}
                Invalid={usernameError}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setDisplayName(e.target.value)
                }
                value={displayName}
              />
              <InputField
                text="Email"
                isRequired={true}
                type={"email"}
                Invalid={emailError}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                value={email}
              />

              <InputField
                text="Password"
                isRequired={true}
                Invalid={passwordError}
                type={"password"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e.target.value)
                }
                value={password}
              />

              <InputField
                text="Confirm Password"
                isRequired={true}
                Invalid={passwordError}
                type={"password"}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPasswordHandler(e.target.value)
                }
                value={confirm_password}
              />
            </div>

            <PasswordChecker
              password={password}
              confirm_password={confirm_password}
            />
            {isStrong &&
              password === confirm_password &&
              displayName !== "" &&
              email !== "" && (
                <div
                  className={`flex flex-col justify-center items-center my-8`}
                >
                  <span
                    className={`${classes["policy-notif"]} flex justify-center items-start mb-8 `}
                  >
                    <div className={classes.checkbox}>
                      {termsAccepted ? (
                        <TickedCheckbox onClick={checkboxHandler} />
                      ) : (
                        <Checkbox onClick={checkboxHandler} />
                      )}
                    </div>
                    <p className="w-full text-center">
                      You agree to the terms and conditions in the {""}
                      <span
                        className={classes.policy}
                        onClick={showTermsHandler}
                      >
                        privacy notice.
                      </span>
                    </p>
                  </span>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Ld0jxInAAAAACy3275Ql2jd_Bb5YDqZWrSnaBdx"
                    onChange={handleRecaptcha}
                    size="normal"
                    theme="light"
                  />

                  {verified && termsAccepted && (
                    <Button
                      text={isPending ? "Loading..." : "Create Account"}
                      isDisabled={!termsAccepted || isPending}
                      style={buttonStyle}
                      type="submit"
                    />
                  )}
                  {error}
                </div>
              )}
          </form>
          <Link to="/" className={"text-slate-400 my-2 w-3/4 text-center"}>
            Already have an account, you can log in{" "}
            <span className=" underline font-semibold text-green-300">
              here
            </span>
            .
          </Link>
          <Link
            to="/forget-password"
            className={"underline font-semibold text-green-300"}
          >
            Forgot password?
          </Link>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Signup;
