import classes from "./Admin.module.css";
import React, { useState, ChangeEvent, useEffect } from "react";
import SidebarAdmin from "components/Admin/SidebarAdmin";
import NavAdmin from "components/Admin/NavAdmin";
import { Role, getDefaultRoles } from "models/organisationRole";
import InputField from "components/utility/Forms/InputField";
import EmptySpace from "components/utility/EmptySpace";
import TaskInterface from "interface/TaskInterface";
import { updateTask } from "models/tasks";
import usePromise from "hooks/utility/usePromise";
import { useTasks } from "hooks/organisation/useTasks";
import toast, { Toaster } from "react-hot-toast";

export default function AdminTasks() {
  const { tasks } = useTasks();
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const { isLoading } = usePromise();

  useEffect(() => {
    setRoles(getDefaultRoles());
    setSelectedRole(getDefaultRoles()[0]);

    // FOR TESTING
    //addTask("Growthcircles", "Number of growthcircles sessions to host");
    //addTask("Co-Facilitation", "Number of growthcircles sessions to co-facilitate");
    //addTask("Community of Practices (C.O.P)", "Number of community of practices to complete");
  }, []);

  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedRoleName = e.target.value;
    const newSelectedRole = roles.find(
      (role) => role.name === selectedRoleName
    );
    if (newSelectedRole) {
      setSelectedRole(newSelectedRole);
    }
  };

  const handleTaskTargetChange = (taskId: string, target: number) => {
    if (!selectedRole) return;
    updateTask(taskId, { [selectedRole?.name as keyof TaskInterface]: target });
    toast.success("Task requirement was updated successfully.");
  };

  const handleTaskDurationChange = (taskId: string, target: number) => {
    if (!selectedRole) return;
    updateTask(taskId, { durationInDays: target });
    toast.success("Task duration was updated successfully.");
  };

  const handleTaskRoleRequiredChange = (
    taskId: string,
    newRoleRequired: string
  ) => {
    updateTask(taskId, { gcRoleRequired: newRoleRequired });
    toast.success("Task requirement was updated successfully.");
  };

  return (
    <div>
      <NavAdmin />
      <Toaster toastOptions={{ duration: 5000 }} position={"bottom-center"} />
      <div className={`${classes.container} min-h-screen`}>
        <SidebarAdmin />
        <div className={`${classes.main}`}>
          <div className={classes.title}>
            <h4>Tasks</h4>
          </div>
          <div style={{ color: "var(--text-colour)" }}>
            <EmptySpace />
            Role:
            <InputField
              name={"selected organisation"}
              type="select"
              htmlFor={"organisation"}
              onChange={handleRoleChange}
              options={roles.map((role) => role.name)}
              value={selectedRole?.name ?? ""}
            />
            {tasks.length > 0 &&
              tasks.map((task) => {
                return (
                  <React.Fragment key={task.id}>
                    <div className="flex flex-col my-6 justify-start items-start p-5 border-2 border-gray-200">
                      <div className="flex items-center gap-2">
                        <p className="font-bold mr-2">Task Title:</p>
                        <p>{task.taskTitle}</p>
                      </div>
                      <EmptySpace />
                      <p className="font-bold">Task Description:</p>
                      <p>{task.description}</p>
                      <EmptySpace />
                      <div className="flex items-center gap-2">
                        <p className="font-bold mr-2">Target: </p>
                        <input
                          disabled={isLoading}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => {
                            handleTaskTargetChange(
                              task.id,
                              parseInt(e.target.value)
                            );
                          }}
                          value={
                            task[selectedRole?.name as keyof TaskInterface]
                          }
                        />
                      </div>
                      <EmptySpace />
                      <div className="flex items-center gap-2">
                        <p className="font-bold mr-2">Duration: </p>
                        <input
                          disabled={isLoading}
                          type="number"
                          min={1}
                          step={1}
                          onChange={(e) => {
                            handleTaskDurationChange(
                              task.id,
                              parseInt(e.target.value)
                            );
                          }}
                          value={task.durationInDays}
                        />
                        <span className="text-lg">
                          day{task.durationInDays > 1 ? "s" : ""}
                        </span>
                      </div>
                      <EmptySpace />
                      <div className="flex items-center gap-2">
                        <p className="font-bold mr-2">Role required: </p>
                        <InputField
                          type="select"
                          htmlFor={"role"}
                          onChange={(e) =>
                            handleTaskRoleRequiredChange(
                              task.id,
                              e.target.value
                            )
                          }
                          options={roles.map((role) => role.name)}
                          value={task.gcRoleRequired}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
