import { Modal } from "flowbite-react";
import { useAuthContext } from "hooks/useAuthContext";
import { useGrowthCircleContext } from "hooks/useGrowthCircleContext";
import {
  CustomTextEditorBlock,
  DATA_CONSENT_VALIDITY_OPTIONS,
  defaultDataConsentContent,
} from "interface/OrganisationInterface";
import { getOrgByName } from "models/organisation";
import { acknowledgeReflectionCopyToInstructor } from "models/profile";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";

/**
 * A modal for users to acknowlegde that a copy of their reflections
 * will be sent to their instructors.
 *
 * @returns DataConsent component.
 */
export default function DataConsent() {
  // TODO: Refactor into a Organisation component for SUSS.
  const { profile } = useAuthContext();
  const { growthCircleSession } = useGrowthCircleContext();
  const [accept, setAccept] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataConsentContent, setDataConsentContent] =
    useState<CustomTextEditorBlock | null>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const acceptHandler = async () => {
    if (!profile || !growthCircleSession) {
      return;
    }
    await acknowledgeReflectionCopyToInstructor(profile.uid);
    const org = await getOrgByName(growthCircleSession?.type);
    const validityUnit: DATA_CONSENT_VALIDITY_OPTIONS =
      org?.dataConsentValidityUnit || "hour(s)";

    // Calculate the validity period in hours
    let validityPeriodInHours = org?.dataConsentValidityPeriod || 0;

    switch (validityUnit) {
      case "hour(s)":
        // eslint-disable-next-line
        validityPeriodInHours = validityPeriodInHours; // 1 hour = 1 hour
        break;
      case "day(s)":
        validityPeriodInHours = validityPeriodInHours * 24; // 1 day = 24 hours
        break;
      case "week(s)":
        validityPeriodInHours = validityPeriodInHours * 7 * 24; // 1 week = 7 days = 168 hours
        break;
      case "month(s)":
        validityPeriodInHours = validityPeriodInHours * 30 * 24; // 1 month = 30 days = 720 hours (Assumption)
        break;
      case "year(s)":
        validityPeriodInHours = validityPeriodInHours * 365 * 24; // 1 year = 365 days = 8760 hours
        break;
      default:
        validityPeriodInHours = 0; // Default case for invalid validityUnit
    }
    Cookies.set(`dataConsentAccept_${growthCircleSession.type}`, "true", {
      expires: validityPeriodInHours / 24,
    }); // x hours = x/24 of a day
    window.location.reload();
  };

  useEffect(() => {
    if (!growthCircleSession) return;
    const fetchData = async () => {
      const existingOrg = await getOrgByName(growthCircleSession?.type);
      if (!existingOrg) return;
      setShowModal(
        existingOrg.enableCustomDataConsent
          ? existingOrg.enableCustomDataConsent
          : false
      );
      setDataConsentContent(
        existingOrg.customDataConsent ? existingOrg.customDataConsent : null
      );
    };

    fetchData();

    // eslint-disable-next-line
  }, [growthCircleSession]);

  useEffect(() => {
    const contentState = convertFromRaw(
      dataConsentContent || defaultDataConsentContent
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [dataConsentContent]);

  return (
    <Modal show={showModal} size="md" popup={true} className="min-h-full">
      <Modal.Body className="relative px-5">
        <div className="text-center py-5">
          <h3 className="mb-5 py-4 text-lg font-normal text-gray-500 dark:text-gray-400">
            <Editor
              toolbar={{ options: [] }}
              readOnly={true}
              editorState={editorState}
              toolbarClassName="border-none"
              wrapperClassName="border-none"
              editorClassName="p-2 select-none"
              onEditorStateChange={() => {}}
            />
          </h3>

          <div className="flex justify-center items-start py-4">
            <label className="text-gray-500 dark:text-gray-400">
              <input
                onChange={() => setAccept(!accept)}
                type="checkbox"
                className="mx-2 text-main-colour"
              />
              I acknowledge.
            </label>
          </div>

          <div className="flex justify-center gap-4">
            <button
              onClick={acceptHandler}
              className={` ${
                !accept ? "bg-slate-300" : "bg-main-colour"
              } text-default shadow-md rounded-full px-12 py-2`}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
