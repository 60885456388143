import { Participant } from "interface/ParticipantInterface";
import classes from "./CheckInCheckOutModal.module.css";
import React from "react";
import SliderDisplay from "components/SliderCustom/SliderDisplay";
import EmptySpace from "components/utility/EmptySpace";
import FirstNameAvatar from "components/utility/FirstNameAvatar";
import useGetParticipants from "hooks/useGetParticipants";
import { ReactComponent as ExclamationMark } from "assets/ExclamationMark.svg";
import Organisation from "interface/OrganisationInterface";

interface Props {
  participants: Participant[];
  keyTitle: string;
  organisation?: Organisation;
}

const CheckInCheckOutOrgModal: React.FC<Props> = ({
  participants,
  keyTitle,
  organisation,
}) => {
  let redArr: Participant[] = [];
  let notRedArr: Participant[] = [];
  const { getParticipantColourIndex } = useGetParticipants();

  const getArr = (participants: Participant[]) => {
    participants.map((p) => {
      if (
        p.orgCheckOut !== undefined &&
        p.orgCheckIn !== undefined &&
        p.orgCheckIn[keyTitle] > p.orgCheckOut[keyTitle]
      ) {
        return redArr.push(p);
      } else {
        return notRedArr.push(p);
      }
    });
  };

  getArr(participants);

  return (
    <div className={`${classes["container"]}`}>
      <h1
        className={`${classes["title"]} underline underline-offset-8 decoration-2 mb-2`}
      >
        For facilitator info only
      </h1>
      <h1 className={classes["title"]}>
        {keyTitle.replace(/_/g, " ").toUpperCase()}
      </h1>
      <div className={classes.legend}>
        <div className="flex items-center gap-2">
          <svg width="10" height="10" className={classes.Circle}>
            <circle cx="5" cy="5" r="5" fill="lightgrey" />
          </svg>
          <div>Before</div>
        </div>
        <div className="flex items-center gap-2">
          <svg width="10" height="10" className={classes.Circle}>
            <circle cx="5" cy="5" r="5" fill="#FAD297" />
          </svg>
          <div>After</div>
        </div>
        {organisation?.enableFlagRating && (
          <div className="flex items-center gap-2">
            <div>
              <ExclamationMark />
            </div>
            <span>After {"<"} Before </span>
          </div>
        )}
      </div>
      <div className={classes["slider-container"]}>
        {redArr.length !== 0 && organisation?.enableFlagRating ? (
          <p className="text-red-500 text-xs text-center mt-2 -mb-6">
            Note you may want to check in with the flagged individuals after the
            session
          </p>
        ) : (
          ""
        )}
        {redArr.map((p, index) => {
          if (p.orgCheckOut === undefined) {
            return false;
          }
          return (
            <React.Fragment key={index}>
              <div className="w-full flex flex-col items-center">
                <div className="relative flex items-center -bottom-10">
                  {organisation?.enableFlagRating && (
                    <ExclamationMark width={30} height={30} />
                  )}
                  <div className={classes["name-container"]}>
                    <FirstNameAvatar
                      name={p.userName}
                      participant={p}
                      styleTriggered={true}
                      participantNumber={getParticipantColourIndex(p.userId)}
                    />
                    <div
                      className={`${
                        organisation?.enableFlagRating
                          ? classes["flag-red"]
                          : "text-standard"
                      }`}
                    >
                      {p.userName}
                    </div>
                  </div>
                </div>
                <SliderDisplay
                  max={organisation?.customMaxRating ?? 10}
                  withBackground={false}
                  value={undefined}
                  checkIn={
                    p.orgCheckIn && p.orgCheckIn[keyTitle] >= 0
                      ? p.orgCheckIn[keyTitle]
                      : undefined
                  }
                  checkOut={
                    p.orgCheckOut && p.orgCheckOut[keyTitle] >= 0
                      ? p.orgCheckOut[keyTitle]
                      : undefined
                  }
                />
              </div>
              <EmptySpace height="2vh" width="100%" />
            </React.Fragment>
          );
        })}
        {organisation?.enableFlagRating && (
          <div className="w-full mt-3 border-dashed border-yellow-900 border"></div>
        )}
        {notRedArr.map((p, index) => {
          if (p.orgCheckOut === undefined) {
            return false;
          }
          return (
            <React.Fragment key={index}>
              <div
                className={`${classes["name-container"]} relative -bottom-10`}
              >
                <FirstNameAvatar
                  name={p.userName}
                  participant={p}
                  styleTriggered={true}
                  participantNumber={getParticipantColourIndex(p.userId)}
                />
                <div>{p.userName}</div>
              </div>
              {p.checkIn !== undefined && (
                <SliderDisplay
                  max={organisation?.customMaxRating ?? 10}
                  withBackground={false}
                  value={undefined}
                  checkIn={
                    p.orgCheckIn && p.orgCheckIn[keyTitle] >= 0
                      ? p.orgCheckIn[keyTitle]
                      : undefined
                  }
                  checkOut={
                    p.orgCheckOut && p.orgCheckOut[keyTitle] >= 0
                      ? p.orgCheckOut[keyTitle]
                      : undefined
                  }
                />
              )}
              <EmptySpace height="2vh" />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CheckInCheckOutOrgModal;
