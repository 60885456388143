import Organisation from "interface/OrganisationInterface";
import { Profile } from "interface/ProfileInterface";
import { OrgUser } from "interface/OrgUserInterface";
import { getUserById, getUserByEmail } from "models/profile";
import { projectFirestore, timestamp } from "../firebase/config";
import {
  DocumentData,
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { addErrorLog } from "models/organisationLog";
import { FIRESTORE_PATH_ORGANISATIONS } from "models/organisation";
import { FIRESTORE_SUBPATH_ORGUSERS } from "hooks/organisation/useOrganisation";

/**
 * Helper method to add one orguser without creating fields manually, only if doesn't exist
 * @param organisation Organisation
 * @param profile User profile
 * @param role Role to add with
 * @returns Promise
 */
export const createAndAddOneOrgUserIfNotExists = async (
  organisation: Organisation,
  profile: Profile,
  role: string
) => {
  console.log("create and add orguser if does not exist");
  const get = await projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .where("user_id", "==", profile.uid)
    .where("org_id", "==", organisation.id)
    .get();

  // >= 1 in case any bug happens, should ideally be equal to 1
  if (get.docs.length >= 1) {
    return;
  }

  let orguser: Omit<OrgUser, "joinedOn"> = {
    user_id: profile.uid,
    org_id: organisation.id,
    role: role,
  };

  return await addOneOrgUser(orguser);
};

/**
 * Helper method to add one orguser without creating fields manually
 * @param organisation Organisation
 * @param profile User profile
 * @param role Role to add with
 * @returns Promise
 */
export const createAndAddOneOrgUser = async (
  organisation: Organisation,
  profile: Profile,
  role: string
) => {
  let orguser: Omit<OrgUser, "joinedOn"> = {
    user_id: profile.uid,
    org_id: organisation.id,
    role: role,
  };

  return await addOneOrgUser(orguser);
};

export const userEmailAlreadyInsideOrg = async (
  email: string,
  organisation: Organisation
) => {
  /*
  // THIS USES THE UNUSED ORGUSERS SUB COLLECTION 
  const collection = projectFirestore.collection(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
  );

  try {
    const querySnapshot = await collection
      .where("email", "==", email)
      .where("org_id", "==", organisation.id)
      .get();

    // Check if any documents match the query
    if (!querySnapshot.empty) {
      // User is associated with the organization
      return true;
    } else {
      // User is not associated with the organization
      return false;
    }
  } catch (error) {
    // Handle any errors that occur during the query
    console.error("Error checking user association:", error);
    throw error;
  }
  */
  const docRef = projectFirestore.doc(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}`
  );

  try {
    const ref = await docRef.get();
    const orgData = ref.data();
    const userId = await getUserByEmail(email).then((profile) => {return profile && profile.uid});
    let userFound = false;
    if (orgData && userId) {
      const orgUsers: Record<string, string> = orgData.users;
      userFound = Object.keys(orgUsers).filter((user) => user === userId).length > 0;
    }
    return userFound;
  } catch (error) {
    // Handle any errors that occur during the query
    console.error("Error checking user existence:", error);
    throw error;
  }
};

export const checkIfAlreadyInsideOrg = async (
  profile: Profile,
  organisation: Organisation
) => {
  /*
  const collection = projectFirestore.collection(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
  );

  try {
    const querySnapshot = await collection
      .where("user_id", "==", profile.uid)
      .where("org_id", "==", organisation.id)
      .get();

    // Check if any documents match the query
    if (!querySnapshot.empty) {
      // User is associated with the organization
      return true;
    } else {
      // User is not associated with the organization
      return false;
    }
  } catch (error) {
    // Handle any errors that occur during the query
    console.error("Error checking user association:", error);
    throw error;
  }
  */
  const docRef = projectFirestore.doc(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}`
  );

  try {
    const ref = await docRef.get();
    const orgData = ref.data();
    let userFound = false;
    if (orgData) {
      const orgUsers: Record<string, string> = orgData.users;
      userFound = Object.keys(orgUsers).filter((user) => user === profile.uid).length > 0;
    }
    return userFound;
  } catch (error) {
    // Handle any errors that occur during the query
    console.error("Error checking user association:", error);
    throw error;
  }
};

/**
 * Gets the total number of orgusers
 * @param organisation Current organisation
 * @returns Integer represent the total number of users in organisation
 */
export const getNoOfOrgUsers = async (organisation: Organisation) => {
  /*
  // THIS USES THE UNUSED ORGUSERS SUB COLLECTION 
  const collectionRef = projectFirestore.collection(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
  );
  const snapshot = await collectionRef.get();
  return snapshot.size;
  */

  const docRef = projectFirestore.doc(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}`
  );
  const ref = await docRef.get();
  const orgData = ref.data();
  let userCount = 0;
  if (orgData) {
    const orgUsers: Record<string, string> = orgData.users;
    userCount = Object.values(orgUsers).length;
  }
  return userCount;
};

/**
 * Gets the total number of orgadmin
 * @param organisation Current organisation
 * @returns Integer represent the total number of admins in organisation
 */
export const getNoOfOrgAdmins = async (organisation: Organisation) => {
  /*
  // THIS USES THE UNUSED ORGUSERS SUB COLLECTION 
  const collectionRef = projectFirestore.collection(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
  );
  const snapshot = await collectionRef
    .where("role", "==", "administrator")
    .get();
  
  return snapshot.size;
  */

  const docRef = projectFirestore.doc(
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}`
  );
  const ref = await docRef.get();
  const orgData = ref.data();
  let adminCount = 0;
  if (orgData) {
    const orgUsers: Record<string, string> = orgData.users;
    adminCount = Object.values(orgUsers).filter(role => role === "administrator").length;
  }
  return adminCount;
};

/**
 * Checks if organisation user limit will exceed
 * @param additionalUsers Number of additional users that will be added
 * @param organisation Current organisation
 * @returns a boolean true if limit will be exceeded if additionalUsers are added, and false otherwise
 */
export const exceedsUserLimit = async (
  additionalUsers: number,
  organisation: Organisation
) => {
  if (!organisation.userLimit) return false;
  return (
    additionalUsers + (await getNoOfOrgUsers(organisation)) >
    organisation.userLimit
  );
};

/**
 * Checks if organisation admin limit will exceed
 * @param additionalAdmins Number of additional admins that will be added
 * @param organisation Current organisation
 * @returns a boolean true if limit will be exceeded if additionalAdmins are added, and false otherwise
 */
export const exceedsAdminLimit = async (
  additionalAdmins: number,
  organisation: Organisation
) => {
  return (
    organisation.adminLimit &&
    additionalAdmins + (await getNoOfOrgAdmins(organisation)) >
      organisation.adminLimit
  );
};

/**
 * Adds one orguser to orgusers
 * @param orguser OrgUser fields without timestamp
 * @returns Promise for add operation
 */
export const addOneOrgUser = async (orguser: Omit<OrgUser, "joinedOn">) => {
  let orguser_with_timestamp: OrgUser = {
    ...orguser,
    joinedOn: timestamp.fromDate(new Date()),
  };
  const docRef = projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${orguser.org_id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .doc(orguser_with_timestamp.user_id);
  return docRef.set(orguser_with_timestamp);
};

/**
 * Update all orgusers docs corresponding to user_id
 * @param user_id
 */
/*
export const updateAllByProfile = async (
  user_id: string | undefined,
  update: Partial<OrgUser>
) => {
  if (!user_id) {
    return;
  }
  const collection = projectFirestore.collection(ORGUSERS_PATH);

  collection
    .where("user_id", "==", user_id)
    .get()
    .then((response) => {
      let batch = projectFirestore.batch();

      response.docs.forEach((doc) => {
        const docRef = collection.doc(doc.id);
        batch.update(docRef, update);
      });
      batch.commit().then(() => {
        console.log(
          `updated ${response.docs.length} docs for user_id:", user_id`
        );
      });
    });
};
*/

export const getOneOrgUserByEmail = async (email: string, org_id: string) => {
  //console.log("Getting for: ", email);
  const get = await projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${org_id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .where("email", "==", email)
    .where("org_id", "==", org_id)
    .get();

  if (get.docs.length === 1) {
    return get.docs.map((d) => d.data());
  }
};

/**
 * Deletes one orguser entry with user_id in org_id
 * @param user_id
 * @param org_id
 */
export const deleteOneOrgUser = async (
  user_id: string,
  org_id: string,
  profileId: string
) => {
  const get = await projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${org_id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .where("user_id", "==", user_id)
    .where("org_id", "==", org_id)
    .get();

  // get id to use for delete
  const idArr = get.docs.map((d) => d.id);

  if (idArr.length === 1) {
    const id = idArr[0];

    await projectFirestore
      .collection(
        `${FIRESTORE_PATH_ORGANISATIONS}/${org_id}/${FIRESTORE_SUBPATH_ORGUSERS}`
      )
      .doc(id)
      .delete();
  } else {
    const description = `Failed to remove organisation user with user_id: ${user_id}`;
    await addErrorLog(profileId, org_id, "USERS", description);
  }
};

/**  Add necessary orgusers docs for the given organisation. Assumes there are no existing docs in orgusers for this organisation. */
export const addToOrgUsers = async (organisation: Organisation | null) => {
  if (organisation) {
    console.log("Adding to orgusers");

    // Get full user profiles for each id in org
    let resultArr = await Promise.all(
      Object.entries(organisation.users).map(async (entry) => {
        const [id, role] = entry;
        return { user: await getUserById(id), role: role };
      })
    );

    resultArr = resultArr.filter((p) => p.user !== undefined);

    console.log("No. of users in org:", resultArr.length);
    console.log("First user in arr:", resultArr[0]);

    // Map to promises that add a corresponding doc to orgusers
    const addOrgUser = (user: { user: Profile | undefined; role: string }) => {
      if (user && user.user) {
        const org_user: OrgUser = {
          user_id: user.user.id,
          org_id: organisation.id,
          role: user.role,
          joinedOn: timestamp.fromDate(new Date()),
        };

        return projectFirestore
          .collection(
            `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
          )
          .add(org_user);
      }
    };

    const adds = resultArr
      .map((u) => addOrgUser(u))
      .filter((p) => p !== undefined);

    const res = await Promise.all(adds);
    console.log("No.of updates that success:", res.length);
  } else {
    console.log("Org is null");
  }
};

/**
 * Get users corresponding to org from orgusers
 */

export const getOrgUsers = async (organisation: Organisation) => {
  const res = await projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .where("org_id", "==", organisation.id)
    .get();

  return res.docs.map((d) => d.data());
};

export const getOrgUsersForMigration = async (
  organisation: Organisation | null
) => {
  const res = await projectFirestore
    .collection("orgusers")
    .where("org_id", "==", organisation?.id)
    .get();

  return res.docs.map((d) => d.data());
};

/**
 * Get count for users in orgusers corresponding to Organisation
 */
export const getOrgUsersCount = async (
  organisation: Organisation
): Promise<number> => {
  const coll = collection(
    projectFirestore,
    `${FIRESTORE_PATH_ORGANISATIONS}/${organisation.id}/${FIRESTORE_SUBPATH_ORGUSERS}`
  );
  const org_users = query(coll, where("org_id", "==", organisation?.id));
  const snapshot = await getCountFromServer(org_users);
  return snapshot.data().count;
};

export type UserWithRole = { user: Profile; role: string };
// Convert OrgUser -> UserWithRole by requesting with getUserById

/**
 * Converts OrgUser to UserWithRole by requesting with getUserById
 * @param orguser OrgUser from /orgusers collection
 * @returns UserWithRole if user with user_id exists in /users, else undefined
 */
export const getUserWithRoleFromOrgUser = async (
  orguser: OrgUser
): Promise<UserWithRole | undefined> => {
  const profile = await getUserById(orguser.user_id);
  if (profile === undefined) {
    return undefined;
  }

  return {
    user: profile,
    role: orguser.role,
  };
};

/**
 * Converts array of OrgUser to array of UserWithRole (profile + role), filtering undefined
 * @param orgusers Array of OrgUsers
 * @returns  Array of UserWithRole (profile + role) without undefined
 */
export const getUsersWithRoleFromOrgUsers = async (
  orgusers: OrgUser[]
): Promise<UserWithRole[]> => {
  let res = await Promise.all(orgusers.map(getUserWithRoleFromOrgUser));
  const filtered = res.filter((doc) => doc !== undefined) as UserWithRole[];
  return filtered;
};

/**
 * Retrieve all organisations that a user is part of
 * @param user_id user_id of user
 * @returns  Array of organisations that the queried user is a part of
 */
export const getAllOrganisations = async (user_id: string) => {
  try {
    const historyQuery = projectFirestore
      .collectionGroup("orgusers")
      .where("user_id", "==", user_id);

    const querySnapshot = await historyQuery.get(); // Execute the query once to get all matching documents

    const organisations: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      organisations.push(doc.data() as DocumentData);
    });

    console.log(organisations);
  } catch (error) {
    console.error("Error fetching documents: ", error);
  }
};

type OrgUserUpdate = Partial<OrgUser>;

export const updateOrgUserByIds = async (
  user_id: string,
  org_id: string,
  update: OrgUserUpdate
) => {
  const res = await projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${org_id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .where("user_id", "==", user_id)
    .where("org_id", "==", org_id)
    .get();

  const id = res.docs.map((doc) => doc.id)[0];

  await projectFirestore
    .collection(
      `${FIRESTORE_PATH_ORGANISATIONS}/${org_id}/${FIRESTORE_SUBPATH_ORGUSERS}`
    )
    .doc(id)
    .update(update);
};

/**
 * Updates the same user for ALL organisations it is in
 * Currently used to update displayName
 * @param user_id
 * @param update
 */
/*
export const updateAllOrgUserByIds = async (
  user_id: string,
  update: OrgUserUpdate
) => {
  const res = await projectFirestore
    .collection(ORGUSERS_PATH)
    .where("user_id", "==", user_id)
    .get();

  const updates = res.docs.map((doc) => {
    const id = doc.id;
    return projectFirestore.collection(ORGUSERS_PATH).doc(id).update(update);
  });

  await Promise.all(updates);
};
*/
