import { projectFirestore } from "../../firebase/config";
import { useEffect, useState } from "react";
import TaskInterface from "interface/TaskInterface";

export const useTasks = () => {
  const [tasks, setTasks] = useState<TaskInterface[]>([]);

  const getTasks = () => {
    const unsubscribe = projectFirestore.collection("Tasks").onSnapshot(
      (snapshot) => {
        const taskItems: TaskInterface[] = [];

        snapshot.forEach((doc) => {
          taskItems.push(doc.data() as TaskInterface);
        });

        setTasks(taskItems);
      },
      (error) => {
        console.error("Error fetching tasks:", error);
      }
    );

    return () => {
      unsubscribe();
    };
  };
  useEffect(() => {
    getTasks();
    // eslint-disable-next-line
  }, []);

  return {
    tasks,
  };
};
