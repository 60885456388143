import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FiUsers } from "react-icons/fi";
import { FaTasks } from "react-icons/fa";
import { PiCirclesThreeFill } from "react-icons/pi";

import {
  FaUniversalAccess,
  FaComment,
  FaHome,
  FaSignOutAlt,
  FaVideo,
} from "react-icons/fa";
import { MdSelfImprovement } from "react-icons/md";
import { GiMountainRoad, GiSkills } from "react-icons/gi";
import { useLogout } from "hooks/useLogout";

import { useAuthContext } from "hooks/useAuthContext";
import {
  TbBrandGoogleAnalytics,
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
} from "react-icons/tb";
import { getOrgsWithPermission } from "models/organisation";
import { P_CAN_VIEW_OVERALL_SELF_DISCOVERY } from "models/permission";
import Organisation from "interface/OrganisationInterface";
import useOrganisationContext from "hooks/organisation/useOrganisationContext";

export default function SidebarAdmin() {
  const [isVisible, setVisible] = useState(false);
  const { profile } = useAuthContext();

  const { logout } = useLogout();
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleViewSidebar = () => {
    setVisible(!isVisible);
  };
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { organisations: globalOrgs } = useOrganisationContext();

  useEffect(() => {
    // Disable animation on initial load
    setShouldAnimate(false);

    // Simulate a delay (adjust as needed)
    const delay = setTimeout(() => {
      setShouldAnimate(true);
    }, 100);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  // Function to save isCollapsed to local storage
  const saveToLocalStorage = (key: string, value: boolean) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving to local storage:", error);
    }
  };

  const loadFromLocalStorage = (key: string) => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
      console.error("Error loading from local storage:", error);
      return null;
    }
  };
  useEffect(() => {
    if (!profile) return;
    getOrgsWithPermission(profile, P_CAN_VIEW_OVERALL_SELF_DISCOVERY).then(
      async (orgs) => {
        setOrganisations(orgs);
      }
    );
  }, [profile]);

  useEffect(() => {
    const storedIsCollapsed = loadFromLocalStorage("isGCCollapsed");
    if (storedIsCollapsed !== null) {
      setIsCollapsed(storedIsCollapsed);
    }
  }, []);

  const handleToggleCollapse = () => {
    const newIsCollapsed = !isCollapsed;
    setIsCollapsed(newIsCollapsed);
    saveToLocalStorage("isGCCollapsed", newIsCollapsed);
  };

  return (
    <>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        onClick={handleViewSidebar}
        className="absolute top-0 text-white inline-flex  h-10 items-center p-2 mt-2 ml-3 text-sm shadow-none md:hidden "
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={
          isVisible
            ? `relative ${
                shouldAnimate ? "ease-in-out duration-300" : ""
              }    md:block w-fit min-h-screen bg-red-400 border-t-2 border-t-red-200/[0.8] z-50`
            : `hidden min-h-screen ${
                shouldAnimate ? "ease-in-out duration-300" : ""
              } md:block ${
                isCollapsed ? "w-14" : "w-80"
              } bg-red-400 border-t-2 border-t-red-200/[0.8]`
        }
        aria-label="Sidebar"
      >
        <div className="relative overflow-y-auto bg-red-400 transition duration-1000">
          <div
            className={`${
              isCollapsed ? "relative px-4 py-4" : "absolute right-2 top-2"
            }  text-white`}
          >
            {isCollapsed ? (
              <TbLayoutSidebarRightCollapse
                size={25}
                onClick={handleToggleCollapse}
                className="cursor-pointer"
              />
            ) : (
              <TbLayoutSidebarLeftCollapse
                size={25}
                onClick={handleToggleCollapse}
                className="cursor-pointer"
              />
            )}
          </div>

          <ul className="space-y-4 text-white">
            <NavLink to="/all-circles/admin/dashboard" title="Dashboard">
              <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                <TbBrandGoogleAnalytics size={20} className=" shrink-0" />

                <span className={`${isCollapsed ? "hidden" : ""}`}>
                  Dashboard
                </span>
              </li>
            </NavLink>

            {profile && profile.access && (
              <>
                {" "}
                <NavLink to="/all-circles/admin/users">
                  <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                    <FiUsers size={20} className=" shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Manage Users
                    </span>
                  </li>
                </NavLink>
              </>
            )}
            {profile &&
              (profile.access ||
                (globalOrgs &&
                  globalOrgs.some(
                    (org) => org.users[profile.id] === "administrator"
                  ))) && (
                <>
                  <NavLink to="/all-circles/admin/manage-organisations">
                    <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                      <FaUniversalAccess size={20} className="shrink-0" />
                      <span className={`${isCollapsed ? "hidden" : ""}`}>
                        Manage Organisations
                      </span>
                    </li>
                  </NavLink>
                </>
              )}

            {profile && profile.access && (
              <>
                <NavLink to="/all-circles/admin/self-discovery">
                  <li className="transition duration-1000 flex gap-2 bg-red-400  hover:bg-red-300 px-4 py-2">
                    <MdSelfImprovement size={20} className=" shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Self Discovery
                    </span>
                  </li>
                </NavLink>
                <NavLink to="/all-circles/admin/growthcircles-type">
                  <li className="transition duration-1000 flex gap-2 bg-red-400  hover:bg-red-300 px-4 py-2">
                    <PiCirclesThreeFill size={20} className=" shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Growth Circles Type
                    </span>
                  </li>
                </NavLink>
                <NavLink to="/all-circles/admin/manage-topics">
                  <li className="transition duration-1000 flex gap-2 bg-red-400  hover:bg-red-300 px-4 py-2">
                    <FaComment size={20} className="shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Manage General Topics{" "}
                    </span>
                  </li>
                </NavLink>
                <NavLink to="/all-circles/admin/manage-pathways">
                  <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300  px-4 py-2">
                    <GiMountainRoad size={20} className="shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Manage Pathways
                    </span>
                  </li>
                </NavLink>
                <NavLink to="/all-circles/admin/manage-skills">
                  <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                    <GiSkills size={20} className="shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Manage Skills
                    </span>
                  </li>
                </NavLink>
                <NavLink to="/all-circles/admin/manage-videos">
                  <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                    <FaVideo size={20} className="shrink-0" />{" "}
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Manage Videos
                    </span>
                  </li>
                </NavLink>
              </>
            )}

            {profile && profile.access && (
              <>
                {" "}
                <NavLink to="/all-circles/admin/tasks">
                  <li className="transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                    <FaTasks size={20} className=" shrink-0" />
                    <span className={`${isCollapsed ? "hidden" : ""}`}>
                      Manage Tasks
                    </span>
                  </li>
                </NavLink>
              </>
            )}

            {profile?.access !== "admin" && organisations.length > 0 && (
              <NavLink to="/all-circles/admin/self-discovery">
                <li className="transition duration-1000 flex gap-2 bg-red-400  hover:bg-red-300 px-4 py-2">
                  <MdSelfImprovement size={20} className=" shrink-0" />
                  <span className={`${isCollapsed ? "hidden" : ""}`}>
                    Self Discovery
                  </span>
                </li>
              </NavLink>
            )}

            <Link to="/">
              <li className="md:hidden transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                <FaHome size={20} className="shrink-0" />{" "}
                <span className={`${isCollapsed ? "hidden" : ""}`}>Home</span>
              </li>
            </Link>

            <a href="/" onClick={logout}>
              <li className="md:hidden transition duration-1000 flex gap-2 bg-red-400 hover:bg-red-300 px-4 py-2">
                <FaSignOutAlt size={20} className="shrink-0" />{" "}
                <span className={`${isCollapsed ? "hidden" : ""}`}>Logout</span>
              </li>
            </a>
          </ul>
        </div>
      </aside>
    </>
  );
}
