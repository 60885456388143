import { array as badwordsList } from "badwords-list";
import chineseWords from "./badWordsChinese.json";

type FilterOptions = {
  placeHolder?: string;
  englishList?: string[];
  chineseList?: string[];
  whitelist?: string[];
};

class Filter {
  private placeHolder: string;
  private englishList: string[];
  private chineseList: string[];
  private whitelist: Set<string>;

  constructor(options: FilterOptions = {}) {
    this.placeHolder = options.placeHolder || "*";
    this.englishList = [...badwordsList, ...(options.englishList || [])];
    this.chineseList = [
      ...chineseWords.words,
      ...(options.chineseList || []),
    ].sort((a, b) => b.length - a.length);
    this.whitelist = new Set(
      (options.whitelist || []).map((word) => word.toLowerCase())
    );
  }

  public isProfane(string: string): boolean {
    // Check for Chinese profanity
    for (const word of this.chineseList) {
      if (string.includes(word)) {
        return true;
      }
    }

    // Check for English profanity
    const words = string.split(" ");
    for (const word of words) {
      const lowerWord = word.toLowerCase();
      if (
        this.englishList.includes(lowerWord) &&
        !this.whitelist.has(lowerWord)
      ) {
        return true;
      }
    }

    return false;
  }

  private replaceWord(string: string, target: string): string {
    const replacement = this.placeHolder.repeat(target.length);
    return string.replace(new RegExp(target, "g"), replacement);
  }

  private cleanWord(word: string): string {
    return this.placeHolder.repeat(word.length);
  }

  public clean(string: string): string {
    // Clean Chinese profanity
    for (const word of this.chineseList) {
      if (string.includes(word)) {
        string = this.replaceWord(string, word);
      }
    }

    // Clean English profanity
    const words = string.split(" ");
    for (let i = 0; i < words.length; i++) {
      const lowerWord = words[i].toLowerCase();
      if (
        this.englishList.includes(lowerWord) &&
        !this.whitelist.has(lowerWord)
      ) {
        words[i] = this.cleanWord(words[i]);
      }
    }

    return words.join(" ");
  }

  public addWhitelist(words: string[]): void {
    words.forEach((word) => this.whitelist.add(word.toLowerCase()));
  }
}

export default Filter;
