import useNetworkStatus from "hooks/network/useNetworkStatus";

const NetworkStatus = () => {
  const { isOnline, isWeakConnection } = useNetworkStatus();

  return (
    <>
      {!isOnline && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          You are offline
        </div>
      )}
      {isOnline && isWeakConnection && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "orange",
            color: "white",
            textAlign: "center",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          Your internet connection is weak
        </div>
      )}
    </>
  );
};

export default NetworkStatus;
