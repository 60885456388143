import { useEffect, useState } from "react";

const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState<boolean>(true);
  const [isWeakConnection, setWeakConnection] = useState<boolean>(false);

  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);

    if ("connection" in navigator) {
      const connection = (navigator as any).connection;
      const effectiveType = connection?.effectiveType || "";

      // Assume "slow-2g", "2g", or "3g" as weak connections
      setWeakConnection(
        effectiveType === "slow-2g" ||
          effectiveType === "2g" ||
          effectiveType === "3g"
      );
    }
  };

  useEffect(() => {
    updateNetworkStatus();

    const handleConnectionChange = () => {
      updateNetworkStatus();
    };

    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    if ("connection" in navigator) {
      const connection = (navigator as any).connection;
      connection.addEventListener("change", handleConnectionChange);
    }

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);

      if ("connection" in navigator) {
        const connection = (navigator as any).connection;
        connection.removeEventListener("change", handleConnectionChange);
      }
    };
  }, []);

  return { isOnline, isWeakConnection };
};

export default useNetworkStatus;
