import { DocumentData } from "firebase/firestore";
import { isObject } from "lodash";
import { getFacilFeedbackQuestions } from "models/componentSettings/feedback/facilFeedback";
import { getGroupFeedbackQuestions } from "models/componentSettings/feedback/groupFeedback";
import { getSelfFeedbackQuestions } from "models/componentSettings/feedback/selfFeedback";
import { useEffect, useState } from "react";
import {
  getCurrentMonthDateRange,
  isBetweenStartAndEndDates,
  setToStartOfDay,
} from "utility/dateHandling";
import { TEST_ORGANISATIONS } from "utility/growthCircles";
import { projectFirestore } from "../firebase/config";
import useOrganisationContext from "./organisation/useOrganisationContext";
//import { getOrgUserById, getOrgUsers } from "./organisation/useOrganisation";

export interface HierarchicalState {
  state: boolean;
  subGroupStates: boolean[];
}

export interface HierarchicalNames {
  name: string;
  subGroupNames: string[];
}

export interface HierarchicalData {
  users: string[];
  subGroupUsers: Record<string, string[]>;
}

export const useDashboard = () => {
  const { selectedOrganisation } = useOrganisationContext();
  const { monthStartDate, monthEndDate } = getCurrentMonthDateRange();
  const [startDate, setStartDate] = useState(monthStartDate);
  const [endDate, setEndDate] = useState(monthEndDate);
  const [profiles, setProfiles] = useState<Array<boolean>>([]);
  const [allProfiles, setAllProfiles] = useState<Array<string>>([]);
  const [groups, setGroups] = useState<Array<HierarchicalState>>([]);
  const [allGroups, setAllGroups] = useState<Array<HierarchicalNames>>([]);
  const [allGroupsData, setAllGroupsData] = useState<
    Record<string, HierarchicalData>
  >({});
  const [userCounts, setUserCounts] = useState<Record<string, number>>({});
  const [averageFeedback, setAverageFeedback] = useState<
    [string, Record<number, Record<string, number>>][]
  >([]);
  const [participantHistory, setParticipantHistory] = useState<DocumentData[]>(
    []
  );
  const [filteredParticipantHistory, setFilteredParticipantHistory] =
    useState<DocumentData[]>();
  const [allParticipantHistory, setAllParticipantHistory] = useState<
    DocumentData[]
  >([]);
  const [qualitativeFeedback, setQualitativeFeedback] = useState<
    Record<string, string[]>
  >({});
  const [beforeAverages, setBeforeAverages] = useState<Record<string, number>>(
    {}
  );
  const [afterAverages, setAfterAverages] = useState<Record<string, number>>(
    {}
  );
  const [averagesCount, setAveragesCount] = useState<number>(0);
  const [beforeCustomAverages, setBeforeCustomAverages] = useState<
    Record<string, Record<string, number>>
  >({});
  const [afterCustomAverages, setAfterCustomAverages] = useState<
    Record<string, Record<string, number>>
  >({});
  const [customAveragesCount, setCustomAveragesCount] = useState<
    Record<string, number>
  >({});
  const [rankedTopics, setRankedTopics] = useState<Record<string, number>>({});
  const [rankedTopicsByAgeGroup, setRankedTopicsByAgeGroup] = useState<
    Record<string, Record<string, number>>
  >({});

  const [facilitators, setFacilitators] = useState<string[]>([]);
  const [allFacilitators, setAllFacilitators] = useState(Object);
  const [facilNumbersMeet, setNumbersMeet] = useState<Object[]>();
  const [takeawaysEntries, setTakeAwayEntries] = useState<String[]>();
  const [intentions, setIntentions] = useState<String[]>();
  const [intentionsWithQuestions, setIntentionsWithQuestions] = useState<
    { question: string; answer: string }[]
  >([]);
  const [actionStepsEntries, setAllActionStepsEntries] = useState<String[]>();

  const NO_GROUP_LABEL = "Unassigned to Groups";
  const REMOVED_USERS_LABEL = "Removed Users";

  // --- Functions that fetch data from Firestore ---

  const getAveragePreandPost = () => {
    const updatedParticipantHistory: DocumentData[] = [];
    let historyUnsubscribe: (() => void) | undefined;
    const isNTU =
      selectedOrganisation?.id === "3YnRMzok7KUfDQoGsV1x" ? true : false;
    const excluded = isNTU
      ? [...TEST_ORGANISATIONS, "JHgfKVlI3VyFVf7nURrK", "DdaTigG6EFNDl8ZWKMdf"]
      : TEST_ORGANISATIONS;

    try {
      const historyQuery = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id)
        .collection("participantHistory")
        .where("growthCircleId", "not-in", excluded);

      historyUnsubscribe = historyQuery.onSnapshot((historiesSnapshot) => {
        updatedParticipantHistory.length = 0; // Clear the array
        historiesSnapshot.forEach((historySnapshot) => {
          updatedParticipantHistory.push(
            historySnapshot.data() as DocumentData
          );
        });

        setParticipantHistory(updatedParticipantHistory);
        // Set the state or do whatever you need with the topics
        // setTopicData(updatedTopics);
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (historyUnsubscribe) {
        historyUnsubscribe();
      }
    };
  };

  const getUsers = async () => {
    const excludedProfileTypes: string[] = ["host", "co-host"];

    var result: Record<string, number> = {};

    if (!selectedOrganisation) return;

    /*
    const orgUsers = (await getOrgUsers(selectedOrganisation?.id)).map((user) => user.user_id);

    for (const userId of orgUsers) {
      const orgUser = await getOrgUserById(selectedOrganisation.id, userId);
      if (!orgUser) continue;
      var userType = orgUser.role;
        if (excludedProfileTypes.includes(userType)) continue;
        result[userType] = result[userType] ?? 0;
        result[userType]++;
    }
    */

    const organisationDoc = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation.id);

    organisationDoc.onSnapshot(async (doc) => {
      if (!doc.exists) return;
      const orgData = doc.data();
      if (!orgData) return;
      for (const userId in orgData.users) {
        if (orgData.users.hasOwnProperty(userId)) {
          var userType = orgData.users[userId];
          if (excludedProfileTypes.includes(userType)) continue;
          result[userType] = result[userType] ?? 0;
          result[userType]++;
        }
      }

      setUserCounts(result);
      return result;
    });
  };

  const getFacilitators = () => {
    let historyUnsubscribe: (() => void) | undefined;
    let rolesUnsubscribe: (() => void) | undefined;

    try {
      const organisationDoc = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id);

      historyUnsubscribe = organisationDoc.onSnapshot((doc) => {
        if (doc.exists) {
          const organisationData = doc.data();
          if (organisationData && organisationData.users) {
            const rolesCollectionRef = organisationDoc.collection("roles");
            var facilitatorRoles: string[] = [];

            rolesUnsubscribe = rolesCollectionRef.onSnapshot(
              (rolesSnapshot) => {
                const roles = rolesSnapshot.docs.map((roleDoc) => ({
                  id: roleDoc.id, // Document ID
                  ...roleDoc.data(), // Document data
                }));
                for (const role of roles) {
                  if (role["permissions"]["Generate QR code"] === true) {
                    facilitatorRoles.push(role.id);
                  }
                }

                const users = organisationData.users as Record<string, string>;

                const facilitators: string[] = [];

                for (const userId in users) {
                  if (
                    users.hasOwnProperty(userId) &&
                    facilitatorRoles.includes(users[userId])
                  ) {
                    facilitators.push(userId);
                  }
                }

                setFacilitators(facilitators);
              }
            );
          }
        }
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (historyUnsubscribe) {
        historyUnsubscribe();
      }
      if (rolesUnsubscribe) {
        rolesUnsubscribe();
      }
    };
  };

  const getAllFacilitators = async () => {
    let historyUnsubscribe: (() => void) | undefined;

    try {
      const organisationsQuery = projectFirestore.collection("organisations");

      historyUnsubscribe = organisationsQuery.onSnapshot((querySnapshot) => {
        const facilitators: string[] = [];

        querySnapshot.forEach((doc) => {
          const organisationData = doc.data();

          if (organisationData && organisationData.users) {
            const users = organisationData.users as Record<string, string>;

            for (const userId in users) {
              if (
                users.hasOwnProperty(userId) &&
                users[userId] === "facilitator"
              ) {
                facilitators.push(userId);
              }
            }
          }
        });

        setAllFacilitators(facilitators);
      });
    } catch (error) {
      console.error(error);
    }

    return () => {
      if (historyUnsubscribe) {
        historyUnsubscribe();
      }
    };
  };

  const getAllParticipants = async () => {
    try {
      const excluded = TEST_ORGANISATIONS;

      const historyQuery = projectFirestore
        .collectionGroup("participantHistory") // Use collectionGroup to query across all organizations
        .where("growthCircleId", "not-in", excluded);

      historyQuery.onSnapshot((historiesSnapshot) => {
        const updatedParticipantHistory: DocumentData[] = []; // Use a new array instead of clearing the existing one

        historiesSnapshot.forEach((historySnapshot) => {
          updatedParticipantHistory.push(
            historySnapshot.data() as DocumentData
          );
        });

        setAllParticipantHistory(updatedParticipantHistory);
        // Set the state or do whatever you need with the data
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getAllGroups = () => {
    try {
      const organisationDoc = projectFirestore
        .collection("organisations")
        .doc(selectedOrganisation?.id);

      const groupsQuery = organisationDoc.collection("groups");
      var noGroup = selectedOrganisation
        ? Object.keys(selectedOrganisation?.users)
        : [];

      groupsQuery.onSnapshot(async (groupsSnapshot) => {
        const groupNames: HierarchicalNames[] = [];
        const groupStates: HierarchicalState[] = [];
        const groupDatas: Record<string, HierarchicalData> = {};

        groupsSnapshot.forEach((groupDoc) => {
          const subGroupQuery = groupsQuery
            .doc(groupDoc.id)
            .collection("SubGroups");

          subGroupQuery.onSnapshot(async (subGroupSnapshot) => {
            var docData = groupDoc.data();

            var groupName: HierarchicalNames = {
              name: `${docData["groupName"]} & Subgroups`,
              subGroupNames: [],
            };
            var groupState: HierarchicalState = {
              state: true,
              subGroupStates: [],
            };
            var groupData: HierarchicalData = {
              users: docData["users"] ?? [],
              subGroupUsers: {},
            };

            subGroupSnapshot.forEach((subGroupDoc) => {
              var subGroupDocData = subGroupDoc.data();
              groupName.subGroupNames.push(subGroupDocData["groupName"]);

              groupData.subGroupUsers[subGroupDocData["groupName"]] =
                subGroupDocData["users"];
            });

            groupName.subGroupNames.sort();

            var noSubGroup = groupData.users;

            // noSubGroup should only have users that are not in any subgroups
            // May be uncessary since groupData.users does not contain users in subgroups.
            // (Did not remove in case there are future cases)
            for (const subGroup in groupData.subGroupUsers) {
              noGroup = noGroup.filter(
                (user) => !groupData.subGroupUsers[subGroup].includes(user)
              );
            }

            noGroup = noGroup.filter((user) => !groupData.users.includes(user));

            if (noSubGroup.length > 0) {
              groupName.subGroupNames.unshift(docData["groupName"]);
              groupData.subGroupUsers[docData["groupName"]] = noSubGroup;
            }

            if (noGroup.length > 0) {
              if (!groupNames.some((group) => group.name === NO_GROUP_LABEL)) {
                groupNames.unshift({ name: NO_GROUP_LABEL, subGroupNames: [] });
                groupStates.unshift({ state: true, subGroupStates: [] });
              }
              groupDatas[NO_GROUP_LABEL] = {
                users: noGroup,
                subGroupUsers: {},
              };
            }

            groupState.subGroupStates = groupName.subGroupNames.map(
              (name) => true
            );
            groupNames.push(groupName);

            groupStates.push(groupState);
            groupDatas[`${docData["groupName"]} & Subgroups`] = groupData;
          });
        });

        var removedUsers: string[] = [];
        for (var index = 0; index < participantHistory.length; index++) {
          if (
            selectedOrganisation?.users[participantHistory[index].userId] ===
              undefined &&
            !removedUsers.includes(participantHistory[index].userId)
          ) {
            removedUsers.push(participantHistory[index].userId);
          }
        }
        if (removedUsers.length > 0) {
          groupNames.unshift({ name: REMOVED_USERS_LABEL, subGroupNames: [] });
          groupStates.unshift({ state: true, subGroupStates: [] });
          groupDatas[REMOVED_USERS_LABEL] = {
            users: removedUsers,
            subGroupUsers: {},
          };
        }

        setAllGroups(groupNames);
        setGroups(groupStates);
        setAllGroupsData(groupDatas);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getAllProfiles = async () => {
    //const noProfileTypeLabel = "(No Profile Type)";

    var profiles: string[] = Object.keys(userCounts).map((key) => key);

    /*
    var noProfileType: string[] = [];
    for (var index=0; index < participantHistory.length; index++) {
      if (selectedOrganisation?.users[participantHistory[index].userId] === undefined || 
          !profiles.includes(selectedOrganisation?.users[participantHistory[index].userId])
      ) {
        noProfileType.push(participantHistory[index].userId);
      }
    }
    if(noProfileType.length > 0) {
      profiles.unshift(noProfileTypeLabel);
    }
    */

    setAllProfiles(profiles);
    setProfiles(profiles.map((profile) => true));
  };

  // --- Functions that process the data ---

  const typeToMaxRating = (type: string): number => {
    if (type === "slider") {
      return 10;
    } else if (type === "smileyRating") {
      return 5;
    }
    return 0;
  };

  const calculateFeedbackAverage = (participantHistory: DocumentData[]) => {
    if (!participantHistory || participantHistory.length === 0) {
      return 0; // Return 0 if there are no participants
    }

    const qualitativeFeedbackTypes: String[] = ["textArea"];

    var rawData: Record<string, Record<number, Record<string, number[]>>> = {};
    var order: Record<string, number> = {};
    var tempQuestionTypes: Record<string, number> = {};

    var qualitativeFeedback: Record<string, string[]> = {};
    var qualitativeFeedbackTitles: Record<string, string> = {};

    // Fetch feedback questions from Firestore
    const feedbackQuestionsQuery = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation?.id)
      .collection("feedbackQuestions");

    feedbackQuestionsQuery.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        for (var index in data["subCategories"]) {
          const question = data["subCategories"][index];
          if (!typeToMaxRating(question["type"])) {
            if (qualitativeFeedbackTypes.includes(question["type"])) {
              qualitativeFeedback[question["questionName"]] = [];
              qualitativeFeedbackTitles[question["questionName"]] =
                question["question"]["default"];
            }
            continue;
          }
          rawData[data["title"]] = rawData[data["title"]] ?? {};
          rawData[data["title"]][typeToMaxRating(question["type"])] =
            rawData[data["title"]][typeToMaxRating(question["type"])] ?? {};
          rawData[data["title"]][typeToMaxRating(question["type"])][
            question["questionName"]
          ] = [0, 0];
          tempQuestionTypes[question["questionName"]] = typeToMaxRating(
            question["type"]
          );
        }
        Object.keys(rawData).forEach((category) => {
          order[category] = data["order"];
        });
      });

      const selfFeedbackQuestions = getSelfFeedbackQuestions(
        selectedOrganisation ? selectedOrganisation.name : ""
      );
      selfFeedbackQuestions.subCategories.forEach((subCategory) => {
        subCategory.questions.forEach((question) => {
          if (!typeToMaxRating(question["type"])) {
            if (qualitativeFeedbackTypes.includes(question["type"])) {
              qualitativeFeedback[question["questionName"]] = [];
              qualitativeFeedbackTitles[question["questionName"]] =
                question["question"]["default"];
            }
          } else {
            rawData[selfFeedbackQuestions.title] =
              rawData[selfFeedbackQuestions.title] ?? {};
            rawData[selfFeedbackQuestions.title][
              typeToMaxRating(question["type"])
            ] =
              rawData[selfFeedbackQuestions.title][
                typeToMaxRating(question["type"])
              ] ?? {};
            rawData[selfFeedbackQuestions.title][
              typeToMaxRating(question["type"])
            ][question["questionName"]] = [0, 0];
            tempQuestionTypes[question["questionName"]] = typeToMaxRating(
              question["type"]
            );
          }
        });
      });
      order[selfFeedbackQuestions.title] = -3;

      const facilFeedbackQuestions = getFacilFeedbackQuestions(
        selectedOrganisation ? selectedOrganisation.name : ""
      );
      facilFeedbackQuestions.subCategories.forEach((subCategory) => {
        subCategory.questions.forEach((question) => {
          if (!typeToMaxRating(question["type"])) {
            if (qualitativeFeedbackTypes.includes(question["type"])) {
              qualitativeFeedback[question["questionName"]] = [];
              qualitativeFeedbackTitles[question["questionName"]] =
                question["question"]["default"];
            }
          } else {
            rawData[facilFeedbackQuestions.title] =
              rawData[facilFeedbackQuestions.title] ?? {};
            rawData[facilFeedbackQuestions.title][
              typeToMaxRating(question["type"])
            ] =
              rawData[facilFeedbackQuestions.title][
                typeToMaxRating(question["type"])
              ] ?? {};
            rawData[facilFeedbackQuestions.title][
              typeToMaxRating(question["type"])
            ][question["questionName"]] = [0, 0];
            tempQuestionTypes[question["questionName"]] = typeToMaxRating(
              question["type"]
            );
          }
        });
      });
      order[facilFeedbackQuestions.title] = -2;

      const groupFeedbackQuestions = getGroupFeedbackQuestions(
        selectedOrganisation ? selectedOrganisation.name : ""
      );
      groupFeedbackQuestions.subCategories.forEach((subCategory) => {
        subCategory.questions.forEach((question) => {
          if (!typeToMaxRating(question["type"])) {
            if (qualitativeFeedbackTypes.includes(question["type"])) {
              qualitativeFeedback[question["questionName"]] = [];
              qualitativeFeedbackTitles[question["questionName"]] =
                question["question"]["default"];
            }
          } else {
            rawData[groupFeedbackQuestions.title] =
              rawData[groupFeedbackQuestions.title] ?? {};
            rawData[groupFeedbackQuestions.title][
              typeToMaxRating(question["type"])
            ] =
              rawData[groupFeedbackQuestions.title][
                typeToMaxRating(question["type"])
              ] ?? {};
            rawData[groupFeedbackQuestions.title][
              typeToMaxRating(question["type"])
            ][question["questionName"]] = [0, 0];
            tempQuestionTypes[question["questionName"]] = typeToMaxRating(
              question["type"]
            );
          }
        });
      });
      order[groupFeedbackQuestions.title] = -1;

      const staticTitleConversions = (title: string) => {
        if (title === facilFeedbackQuestions.category) {
          return facilFeedbackQuestions.title;
        } else if (title === selfFeedbackQuestions.category) {
          return selfFeedbackQuestions.title;
        } else if (title === groupFeedbackQuestions.category) {
          return groupFeedbackQuestions.title;
        }
        return title;
      };

      // Loop through each participant's data
      participantHistory.forEach((participant) => {
        if (participant.feedbackReflection) {
          Object.entries(participant.feedbackReflection).forEach(
            ([category, items]) => {
              category = staticTitleConversions(category);
              if (!isObject(items)) return;
              Object.entries(items).forEach(([item, rating]) => {
                const ratingValue = parseFloat(rating);
                if (!isNaN(ratingValue)) {
                  if (
                    rawData[category] &&
                    rawData[category][tempQuestionTypes[item]] &&
                    rawData[category][tempQuestionTypes[item]][item]
                  ) {
                    rawData[category][tempQuestionTypes[item]][item][0] +=
                      ratingValue;
                    rawData[category][tempQuestionTypes[item]][item][1]++;
                  }
                } else if (qualitativeFeedback[item]) {
                  qualitativeFeedback[item].push(rating);
                }
              });
            }
          );
        }
      });

      var averageData: Record<
        string,
        Record<number, Record<string, number>>
      > = {};

      Object.entries(rawData).forEach(([category, ratingCategories]) => {
        Object.entries(ratingCategories).forEach(([ratingCategory, items]) => {
          Object.entries(items).forEach(([item, rating]) => {
            if (!rating[1]) return;
            averageData[category] = averageData[category] ?? {};
            averageData[category][ratingCategory] =
              averageData[category][ratingCategory] ?? {};
            averageData[category][ratingCategory][item] = rating[0] / rating[1];
            averageData[category][ratingCategory][item] = parseFloat(
              averageData[category][ratingCategory][item].toFixed(2)
            );
          });
        });
      });

      // Order: Custom (in order), Self, Facilitator, Group
      var result = Object.entries(averageData).sort((a, b) => {
        let orderA = order[a[0]];
        let orderB = order[b[0]];

        orderA = orderA < 0 ? orderA + Object.keys(order).length + 3 : orderA;
        orderB = orderB < 0 ? orderB + Object.keys(order).length + 3 : orderB;

        if (orderA !== orderB) {
          return orderA - orderB;
        }
        return a[0].localeCompare(b[0]);
      });

      var result2: Record<string, string[]> = Object.fromEntries(
        Object.entries(qualitativeFeedback)
          .filter(([key, value]) => value.length > 0) // Filter out empty arrays
          .map(([key, value]) => [qualitativeFeedbackTitles[key] || key, value]) // Rename keys
      );

      setAverageFeedback(result);
      setQualitativeFeedback(result2);
      return result;
    });
  };

  const getAllIntentions = (participantHistory: DocumentData[]) => {
    if (!participantHistory || participantHistory.length === 0) {
      return 0; // Return 0 if there are no participants
    }

    const allIntentions: string[] = [];

    participantHistory.forEach((participant) => {
      if (participant.intentions !== undefined) {
        const intentions = participant.intentions;
        if (intentions !== "" && intentions !== undefined) {
          allIntentions.push(intentions);
        }
      }
    });

    setIntentions(allIntentions);

    return allIntentions;
  };

  const getAllIntentionsWithQuestions = (history: DocumentData[] = []) => {
    if (history.length === 0) {
      setIntentionsWithQuestions([]); // Reset state if no participants
      return [];
    }

    const result = history
      .filter(
        (participant) =>
          participant.intentionsRandom &&
          participant.intentionsRandom !== "" &&
          participant.intentionQuestion &&
          participant.intentionQuestion !== ""
      )
      .map((participant) => ({
        question: participant.intentionQuestion,
        answer: participant.intentionsRandom,
      }));

    setIntentionsWithQuestions(result); // Update state
    return result;
  };

  const getAllTakeAwaysEntries = (participantHistory: DocumentData[]) => {
    if (!participantHistory || participantHistory.length === 0) {
      return 0; // Return 0 if there are no participants
    }

    const allTakeAways: string[] = [];

    participantHistory.forEach((participant) => {
      if (
        participant.feedbackReflection &&
        participant.feedbackReflection.feedbackForSelf &&
        participant.feedbackReflection.feedbackForSelf.takeaways !== undefined
      ) {
        const takeaways =
          participant.feedbackReflection.feedbackForSelf.takeaways;

        if (takeaways !== "" && takeaways !== undefined) {
          allTakeAways.push(takeaways);
        }
      }
    });

    setTakeAwayEntries(allTakeAways);

    return allTakeAways;
  };

  const getAllActionStepsEntries = (participantHistory: DocumentData[]) => {
    if (!participantHistory || participantHistory.length === 0) {
      return 0; // Return 0 if there are no participants
    }

    const allActionSteps: string[] = [];

    participantHistory.forEach((participant) => {
      if (
        participant.feedbackReflection &&
        participant.feedbackReflection.feedbackForSelf &&
        participant.feedbackReflection.feedbackForSelf
          .whatActionStepQuestion !== undefined
      ) {
        const actionSteps =
          participant.feedbackReflection.feedbackForSelf.whatActionStepQuestion;

        if (actionSteps !== "" && actionSteps !== undefined) {
          allActionSteps.push(actionSteps);
        }
      }
    });

    setAllActionStepsEntries(allActionSteps);

    return allActionSteps;
  };

  const getUserRecordsCount = (
    userIds: string[],
    participantHistory: DocumentData[]
  ) => {
    const userCounts: {
      [userId: string]: { username: string; count: number };
    } = {};

    // Create a mapping of userId to username and count records
    participantHistory.forEach((participant) => {
      const userId = participant.userId;
      const username = participant.userName;

      // Check if the userId is in the list of userIds to consider
      if (
        userIds &&
        userIds.length > 0 &&
        userIds.includes(userId) &&
        userId &&
        username
      ) {
        if (!userCounts[userId]) {
          userCounts[userId] = {
            username: username,
            count: 0,
          };
        }
        userCounts[userId].count++;
      }
    });

    // Convert the userCounts object to an array for sorting
    const userCountsArray = Object.values(userCounts);

    // Sort the array by count in descending order
    userCountsArray.sort((a, b) => b.count - a.count);

    return userCountsArray;
  };

  const calculateCustomAverages = async (
    participantHistory: DocumentData[]
  ) => {
    // Create objects to store the "Before" and "After" averages
    const beforeAverages: Record<string, Record<string, number>> = {};
    const afterAverages: Record<string, Record<string, number>> = {};
    const allValuesPresent: string = "allValuesPresent";
    const notAllValuesPresent: string = "notAllValuesPresent";

    // Filter out participants with -1 checkIn or checkOut data
    const validParticipants = participantHistory.filter(
      (participant) =>
        participant.orgCheckIn &&
        participant.orgCheckOut &&
        Object.values(participant.orgCheckIn).every((value) => value !== -1) &&
        Object.values(participant.orgCheckOut).every((value) => value !== -1)
    );

    const validParticipantsOnlyBefore = participantHistory.filter(
      (participant) =>
        participant.orgCheckIn &&
        Object.values(participant.orgCheckIn).every((value) => value !== -1)
    );

    const validParticipantsOnlyAfter = participantHistory.filter(
      (participant) =>
        participant.orgCheckOut &&
        Object.values(participant.orgCheckOut).every((value) => value !== -1)
    );

    var counts: Record<string, number> = {};

    // Ensure that there is at least one valid entry in the history
    if (validParticipants.length > 0) {
      // Loop through each participant's "checkIn" and "checkOut" data
      counts["Both"] = validParticipants.length;
      validParticipants.forEach((participant) => {
        const checkInData = participant.orgCheckIn as Record<string, number>;
        const checkOutData = participant.orgCheckOut as Record<string, number>;

        for (const key in checkInData) {
          if (checkInData.hasOwnProperty(key)) {
            beforeAverages[allValuesPresent] =
              beforeAverages[allValuesPresent] ?? {};
            beforeAverages[allValuesPresent][key] =
              (beforeAverages[allValuesPresent][key] || 0) + checkInData[key];
          }
        }

        for (const key in checkOutData) {
          if (checkOutData.hasOwnProperty(key)) {
            afterAverages[allValuesPresent] =
              afterAverages[allValuesPresent] ?? {};
            afterAverages[allValuesPresent][key] =
              (afterAverages[allValuesPresent][key] || 0) + checkOutData[key];
          }
        }
      });

      // Calculate the average for each field
      const numValidParticipants = validParticipants.length;

      for (const key in beforeAverages[allValuesPresent]) {
        if (beforeAverages[allValuesPresent].hasOwnProperty(key)) {
          beforeAverages[allValuesPresent][key] = +(
            beforeAverages[allValuesPresent][key] / numValidParticipants
          ).toFixed(2);
          afterAverages[allValuesPresent][key] = +(
            afterAverages[allValuesPresent][key] / numValidParticipants
          ).toFixed(2);
        }
      }
    }

    // Ensure that there is at least one valid entry in the history
    if (
      validParticipantsOnlyBefore.length > 0 &&
      validParticipantsOnlyBefore.length !== validParticipants.length
    ) {
      // Loop through each participant's "checkIn" and "checkOut" data
      counts["Check-In"] = validParticipantsOnlyBefore.length;
      validParticipantsOnlyBefore.forEach((participant) => {
        const checkInData = participant.orgCheckIn as Record<string, number>;

        for (const key in checkInData) {
          if (checkInData.hasOwnProperty(key)) {
            beforeAverages[notAllValuesPresent] =
              beforeAverages[notAllValuesPresent] ?? {};
            beforeAverages[notAllValuesPresent][key] =
              (beforeAverages[notAllValuesPresent][key] || 0) +
              checkInData[key];
          }
        }
      });

      // Calculate the average for each field
      const numValidParticipants = validParticipantsOnlyBefore.length;

      for (const key in beforeAverages[notAllValuesPresent]) {
        if (beforeAverages[notAllValuesPresent].hasOwnProperty(key)) {
          beforeAverages[notAllValuesPresent][key] = +(
            beforeAverages[notAllValuesPresent][key] / numValidParticipants
          ).toFixed(2);
        }
      }
    }

    if (
      validParticipantsOnlyAfter.length > 0 &&
      validParticipantsOnlyAfter.length !== validParticipants.length
    ) {
      // Loop through each participant's "checkIn" and "checkOut" data
      counts["Check-In"] = validParticipantsOnlyAfter.length;
      validParticipantsOnlyAfter.forEach((participant) => {
        const checkOutData = participant.orgCheckOut as Record<string, number>;

        for (const key in checkOutData) {
          if (checkOutData.hasOwnProperty(key)) {
            afterAverages[notAllValuesPresent] =
              afterAverages[notAllValuesPresent] ?? {};
            afterAverages[notAllValuesPresent][key] =
              (afterAverages[notAllValuesPresent][key] || 0) +
              checkOutData[key];
          }
        }
      });

      // Calculate the average for each field
      const numValidParticipants = validParticipantsOnlyAfter.length;

      for (const key in afterAverages[notAllValuesPresent]) {
        if (afterAverages[notAllValuesPresent].hasOwnProperty(key)) {
          afterAverages[notAllValuesPresent][key] = +(
            afterAverages[notAllValuesPresent][key] / numValidParticipants
          ).toFixed(2);
        }
      }
    }

    setBeforeCustomAverages({ ...beforeAverages });
    setAfterCustomAverages({ ...afterAverages });
    setCustomAveragesCount(counts);
    return { beforeAverages, afterAverages };
  };

  const calculateAverages = async (participantHistory: DocumentData[]) => {
    // Create objects to store the "Before" and "After" averages
    const beforeAverages: Record<string, number> = {};
    const afterAverages: Record<string, number> = {};

    // Filter out participants with -1 checkIn or checkOut data
    const validParticipants = participantHistory.filter(
      (participant) =>
        Object.values(participant.checkIn).every((value) => value !== -1) &&
        Object.values(participant.checkOut).every((value) => value !== -1)
    );

    // Ensure that there is at least one valid entry in the history
    if (validParticipants.length > 0) {
      // Loop through each participant's "checkIn" and "checkOut" data
      validParticipants.forEach((participant) => {
        const checkInData = participant.checkIn as Record<string, number>;
        const checkOutData = participant.checkOut as Record<string, number>;

        for (const key in checkInData) {
          if (checkInData.hasOwnProperty(key)) {
            beforeAverages[key] = (beforeAverages[key] || 0) + checkInData[key];
          }
        }

        for (const key in checkOutData) {
          if (checkOutData.hasOwnProperty(key)) {
            afterAverages[key] = (afterAverages[key] || 0) + checkOutData[key];
          }
        }
      });

      // Calculate the average for each field
      const numValidParticipants = validParticipants.length;

      for (const key in beforeAverages) {
        if (beforeAverages.hasOwnProperty(key)) {
          beforeAverages[key] = +(
            beforeAverages[key] / numValidParticipants
          ).toFixed(2);
          afterAverages[key] = +(
            afterAverages[key] / numValidParticipants
          ).toFixed(2);
        }
      }
    }

    setBeforeAverages({ ...beforeAverages });
    setAfterAverages({ ...afterAverages });
    setAveragesCount(validParticipants.length);
    return { beforeAverages, afterAverages };
  };

  const rankTopics = (participantHistory: DocumentData[]) => {
    // Extract topic data and count occurrences
    const topicCounts: Record<string, number> = {};

    participantHistory.forEach((participant) => {
      const topic = participant.topic as string; // Assuming topic is a string

      // Only count the topic if it's a non-empty string
      if (topic && topic.trim() !== "") {
        topicCounts[topic] = (topicCounts[topic] || 0) + 1;
      }
    });

    // Sort topics by count in descending order
    const rankedTopics = Object.keys(topicCounts).sort(
      (topicA, topicB) => topicCounts[topicB] - topicCounts[topicA]
    );

    // Limit the ranking to a maximum of 5 topics
    rankedTopics.splice(5);

    // Create an object to store the ranked topics and their counts
    const rankedTopicData: Record<string, number> = {};
    rankedTopics.forEach((topic) => {
      rankedTopicData[topic] = topicCounts[topic];
    });

    setRankedTopics(rankedTopicData);
    return rankedTopicData;
  };

  const rankTopicsByAgeGroup = (participantHistory: DocumentData[]) => {
    // Extract topic data and count occurrences within each age group
    const ageGroupTopicCounts: Record<string, Record<string, number>> = {};

    participantHistory.forEach((participant) => {
      const topic = participant.topic as string; // Assuming topic is a string
      const birthYear = participant.birthday as number; // Assuming birthday is a number representing the birth year

      // Calculate age
      const currentYear = new Date().getFullYear();
      const age = currentYear - birthYear;

      // Create an age group string (e.g., "18-24", "25-34", etc.)
      const ageGroup = `${Math.floor(age / 10) * 10}-${
        Math.floor(age / 10) * 10 + 9
      }`;

      // Initialize the topic count for the age group if not already present
      ageGroupTopicCounts[ageGroup] = ageGroupTopicCounts[ageGroup] || {};

      // Only count the topic if it's a non-empty string
      if (topic && topic.trim() !== "") {
        ageGroupTopicCounts[ageGroup][topic] =
          (ageGroupTopicCounts[ageGroup][topic] || 0) + 1;
      }
    });

    // Create an object to store the ranked topics and their counts for each age group
    const rankedTopicsByAgeGroup: Record<string, Record<string, number>> = {};

    // Iterate over age groups
    Object.keys(ageGroupTopicCounts).forEach((ageGroup) => {
      const topicCounts = ageGroupTopicCounts[ageGroup];

      // Sort topics by count in descending order
      const rankedTopics = Object.keys(topicCounts).sort(
        (topicA, topicB) => topicCounts[topicB] - topicCounts[topicA]
      );

      // Limit the ranking to a maximum of 4 topics
      rankedTopics.splice(5);

      // Create an object to store the ranked topics and their counts for the current age group
      const rankedTopicData: Record<string, number> = {};
      rankedTopics.forEach((topic) => {
        rankedTopicData[topic] = topicCounts[topic];
      });

      // Store the ranked topics for the current age group
      rankedTopicsByAgeGroup[ageGroup] = rankedTopicData;
    });

    // Assuming you want to set or return the ranked topics by age group
    setRankedTopicsByAgeGroup(rankedTopicsByAgeGroup);
    return rankedTopicsByAgeGroup;
  };

  /**
   * Filter the participant history by date range
   */
  const filterParticipantHistoryByDate = (
    participantHistory: DocumentData[]
  ) => {
    const filteredParticipantHistory = participantHistory.filter(
      (participant) =>
        isBetweenStartAndEndDates(
          setToStartOfDay(
            startDate
              ? startDate
              : new Date(
                  Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)
                )
          ),
          setToStartOfDay(
            endDate
              ? endDate
              : new Date(
                  new Date(
                    Date.UTC(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      1
                    )
                  ).getTime() - 1
                )
          ),
          participant.createdAt.toDate()
        )
    );

    return filteredParticipantHistory;
  };

  const filterParticipantHistoryByProfile = (
    participantHistory: DocumentData[]
  ) => {
    const filteredProfiles = allProfiles.filter(
      (profile, index) => profiles[index]
    );
    const filteredParticipantHistory = participantHistory.filter(
      (participant) => {
        if (
          filteredProfiles.includes(
            selectedOrganisation
              ? selectedOrganisation.users[participant.userId]
              : ""
          )
        )
          return true;
        if (
          filteredProfiles.includes("(No Profile Type)") &&
          (selectedOrganisation?.users[participant.userId] === undefined ||
            !allProfiles.includes(
              selectedOrganisation?.users[participant.userId]
            ))
        )
          return true;
        return false;
      }
    );

    return filteredParticipantHistory;
  };

  const filterParticipantHistoryByGroup = (
    participantHistory: DocumentData[]
  ) => {
    if (groups.every((group) => group.state === true))
      return participantHistory ?? [];
    const filteredUsers: Record<string, boolean> = {};
    const entries = Object.values(allGroupsData);

    // Loop through the entries with index
    for (let groupIndex = 0; groupIndex < entries.length; groupIndex++) {
      const groupData: HierarchicalData = entries[groupIndex];
      if (groups[groupIndex].state) {
        groupData.users.forEach((userId) => {
          filteredUsers[userId] = true;
        });
      } else {
        const subGroupEntries = Object.values(groupData.subGroupUsers);
        for (
          let subGroupIndex = 0;
          subGroupIndex < subGroupEntries.length;
          subGroupIndex++
        ) {
          if (groups[groupIndex].subGroupStates[subGroupIndex]) {
            groupData.subGroupUsers[
              allGroups[groupIndex].subGroupNames[subGroupIndex]
            ].forEach((userId) => {
              filteredUsers[userId] = true;
            });
          }
        }
      }
    }

    const filteredParticipantHistory = participantHistory.filter(
      (participant) => filteredUsers[participant.userId] || false
    );

    return filteredParticipantHistory;
  };

  // --- UseEffect hooks to fetch and process data ---

  useEffect(() => {
    if (!selectedOrganisation) return;
    getAveragePreandPost();
    getUsers();
    getFacilitators();
    getAllGroups();

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  useEffect(() => {
    getAllGroups();

    // eslint-disable-next-line
  }, [participantHistory]);

  useEffect(() => {
    getAllProfiles();

    // eslint-disable-next-line
  }, [participantHistory, userCounts]);

  useEffect(() => {
    if (!selectedOrganisation) return;

    const organisationDoc = projectFirestore
      .collection("organisations")
      .doc(selectedOrganisation.id);

    organisationDoc.onSnapshot(() => {
      getUsers();
      getAllGroups();
    });

    // eslint-disable-next-line
  }, [selectedOrganisation]);

  useEffect(() => {
    var filteredParticipantHistory = participantHistory;
    filteredParticipantHistory = filterParticipantHistoryByDate(
      filteredParticipantHistory
    );
    filteredParticipantHistory = filterParticipantHistoryByProfile(
      filteredParticipantHistory
    );
    filteredParticipantHistory = filterParticipantHistoryByGroup(
      filteredParticipantHistory
    );

    setFilteredParticipantHistory(filteredParticipantHistory);

    // eslint-disable-next-line
  }, [participantHistory, startDate, endDate, profiles, groups]);

  useEffect(() => {
    if (!filteredParticipantHistory) return;
    calculateAverages(filteredParticipantHistory);
    calculateCustomAverages(filteredParticipantHistory);
    rankTopics(filteredParticipantHistory);
    rankTopicsByAgeGroup(filteredParticipantHistory);
    calculateFeedbackAverage(filteredParticipantHistory);
    getAllIntentions(filteredParticipantHistory);
    getAllTakeAwaysEntries(filteredParticipantHistory);
    getAllActionStepsEntries(filteredParticipantHistory);
    getAllIntentionsWithQuestions(filteredParticipantHistory);
    const count = getUserRecordsCount(facilitators, filteredParticipantHistory);
    setNumbersMeet(count);

    // eslint-disable-next-line
  }, [filteredParticipantHistory]);

  useEffect(() => {
    const count = getUserRecordsCount(facilitators, participantHistory);
    setNumbersMeet(count);

    // eslint-disable-next-line
  }, [facilitators]);

  return {
    //Filter Header variables
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    profiles,
    setProfiles,
    allProfiles,
    groups,
    setGroups,
    allGroups,
    allGroupsData,
    participantHistory,
    filteredParticipantHistory,
    beforeAverages,
    afterAverages,
    averagesCount,
    beforeCustomAverages,
    afterCustomAverages,
    customAveragesCount,
    rankedTopics,
    userCounts,
    averageFeedback,
    qualitativeFeedback,
    facilitators,
    facilNumbersMeet,
    takeawaysEntries,
    intentions,
    intentionsWithQuestions,
    actionStepsEntries,
    rankedTopicsByAgeGroup,
    allParticipantHistory,
    allFacilitators,
    getAllParticipants,
    getAllFacilitators,
    getUserRecordsCount,
    NO_GROUP_LABEL,
    REMOVED_USERS_LABEL,
  };
};

export default useDashboard;
