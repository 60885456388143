import Header from "components/Header/Header";
import SimpleLoader from "components/Loaders/SimpleLoader";
import TabItem from "components/utility/Tab/TabItem";
import GrowthTab from "components/utility/Tab/GrowthTab";
import ContentWrapper from "components/utility/ContentWrapper";
import PageWrapper from "components/utility/PageWrapper";
import { Suspense, lazy, useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { getAllUserPermissions } from "models/organisationRole";
import { getOrganisations } from "models/organisation";

const GrowthFacilitatorTasks = lazy(
  () => import("components/GrowthFacilitatorTasks/GrowthFacilitatorTasks")
);

// Appreciation Tab Items

const SpecialThanksList = lazy(
  () => import("components/SpecialThanks/SpecialThanksList")
);

const AppreciationList = lazy(
  () => import("components/Appreciation/AppreciationList")
);

// Impact Tab Items

const OpportunitiesCompletedList = lazy(
  () => import("components/OpportunitiesCompleted/OpportunitiesCompletedList")
);

const COPCompletedList = lazy(
  () => import("components/COPCompleted/COPCompletedList")
);

// Growth & Learning Tab Items

const BadgesAndCertificatesList = lazy(
  () => import("components/BadgesAndCertificates/BadgesAndCertificatesList")
);

const FacilitatorFeedbackList = lazy(
  () => import("components/FacilitatorFeedback/FacilitatorFeedbackList")
);

const MentorFeedbackList = lazy(
  () => import("components/GCMentorFeedback/MentorFeedbackList")
);

const MyTakeawayList = lazy(
  () => import("components/MyGCTakeaways/MyTakeawayList")
);

// Well-being Tab Items

const MyActionsList = lazy(
  () => import("components/MyGCActions/MyActionsList")
);

const OverallGCTopicsList = lazy(
  () => import("components/OverallGCTopics/OverallGCTopicsList")
);

const WellbeingList = lazy(() => import("components/Wellbeing/WellbeingList"));

const WellbeingComparisonList = lazy(
  () => import("components/WellbeingComparison/WellbeingComparisonList")
);

const MonthlyGCTopicsList = lazy(
  () => import("components/MonthlyGCTopics/MonthlyGCTopicsList")
);

const MyGrowth = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { profile } = useAuthContext();
  const [isFacil, setIsFacil] = useState<boolean>(false);
  const [isTrainee, setIsTrainee] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(
      new Date(
        Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 1)
      ).getTime() - 82800000
    )
  );
  const [month1, setMonth1] = useState<Date>(
    new Date(
      new Date(
        Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 1)
      ).getTime() - 82800000
    )
  );
  const [month2, setMonth2] = useState<Date>(
    new Date(
      new Date(
        Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1, 1)
      ).getTime() - 82800000
    )
  );
  const [year, setYear] = useState<Date>(new Date());

  useEffect(() => {
    if (!profile) return;

    const getProfileRoles = async () => {
      // Get all organisations that the user is part of
      if (profile) {
        const allOrganisations = await getOrganisations(profile);

        // Get array of roles that the user has in each organisation it is a part of
        const orgRoles = await getAllUserPermissions(profile, allOrganisations);

        orgRoles.forEach((org) => {
          if (org.name === "facilitator") {
            setIsFacil(true);
          }

          if (org.name === "trainee") {
            setIsTrainee(true);
          }

          if (org.name === "super administrator") {
            setIsFacil(true);
          }
        });
      } else {
        console.log("no profile");
      }
    };

    getProfileRoles();
  }, [profile]);

  return (
    <PageWrapper>
      {" "}
      <Header />
      <ContentWrapper hasFiveStepper={true}>
        <div className="w-full mb-[100px] flex flex-col justify-center items-center max-w-[340px] md:max-w-[600px]">
          <div className="mb-6">
            <div className="flex-1 text-center">
              <p
                className="text-3xl font-semibold"
                style={{ color: "#4A4A4A" }}
              >
                My Growth & Wellbeing
              </p>
            </div>
          </div>
          <div className="bg-white my-4 w-full rounded-lg">
            <Suspense fallback={<SimpleLoader />}>
              <GrowthFacilitatorTasks isFacil={isFacil} isTrainee={isTrainee} />
            </Suspense>
          </div>
          <GrowthTab setTab={setActiveTab}>
            <TabItem title="Appreciation">
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <SpecialThanksList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <AppreciationList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
            </TabItem>
            <TabItem title="Impact">
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <OpportunitiesCompletedList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <COPCompletedList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
            </TabItem>

            <TabItem title="Growth & Learning">
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <BadgesAndCertificatesList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>

              {isFacil && (
                <div className="bg-white my-4 w-full rounded-lg">
                  <Suspense fallback={<SimpleLoader />}>
                    <FacilitatorFeedbackList
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </Suspense>
                </div>
              )}
              {isTrainee && (
                <div className="bg-white my-4 w-full rounded-lg">
                  <Suspense fallback={<SimpleLoader />}>
                    <MentorFeedbackList
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </Suspense>
                </div>
              )}
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <MyTakeawayList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
            </TabItem>

            <TabItem title="Well-being">
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <MyActionsList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <OverallGCTopicsList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <WellbeingList
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Suspense>
              </div>
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <WellbeingComparisonList year={year} setYear={setYear} />
                </Suspense>
              </div>
              <div className="bg-white my-4 w-full rounded-lg">
                <Suspense fallback={<SimpleLoader />}>
                  <MonthlyGCTopicsList
                    month1={month1}
                    month2={month2}
                    setMonth1={setMonth1}
                    setMonth2={setMonth2}
                  />
                </Suspense>
              </div>
            </TabItem>
          </GrowthTab>
          <div className="flex justify-center gap-2 mt-4">
            <div
              className={`h-[8px] w-[32px] flex items-center justify-center text-white rounded-lg ${
                activeTab === 0 ? "bg-[#7BBCAB]" : "bg-[#C4C4C4]"
              }`}
            />
            <div
              className={`h-[8px] w-[32px] flex items-center justify-center text-white rounded-lg ${
                activeTab === 1 ? "bg-[#7BBCAB]" : "bg-[#C4C4C4]"
              }`}
            />
            <div
              className={`h-[8px] w-[32px] flex items-center justify-center text-white rounded-lg ${
                activeTab === 2 ? "bg-[#7BBCAB]" : "bg-[#C4C4C4]"
              }`}
            />
            <div
              className={`h-[8px] w-[32px] flex items-center justify-center text-white rounded-lg ${
                activeTab === 3 ? "bg-[#7BBCAB]" : "bg-[#C4C4C4]"
              }`}
            />
          </div>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default MyGrowth;
