export default interface TaskInterface {
    id: string,
    taskTitle: string,
    description: string,
    durationInDays: number, 
    gcRoleRequired: string,
    administrator: number,
    participant: number,
    trainee: number,
    facilitator: number,
    coHost: number,
    host: number,
    instructor: number
}

export const defaultTaskModel: TaskInterface = {
    id: "",
    taskTitle: "",
    description: "",
    durationInDays: 30,
    gcRoleRequired: "host",
    administrator: 0,
    participant: 0,
    trainee: 0,
    facilitator: 0,
    coHost: 0,
    host: 0,
    instructor: 0
  };